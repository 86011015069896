import axios from "axios";
import Cookies from 'js-cookie';

const EditProfileHook = async (firstName, lastName, email, img, phone) => {
    const EDIT_PROFILE = process.env.REACT_APP_EDIT_PROFILE;
    const token = Cookies.get('token');

    try {
        await axios.post(EDIT_PROFILE, {
            first_name: firstName,
            last_name: lastName,
            email: email,
            image: img,
            phone: phone
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
        return { done: true, err: null }
    } catch (err) {
        return { done: false, err: err }
    }
}
export default EditProfileHook