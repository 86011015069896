import { useState } from "react";
import NavComponent from "../UI/CustomUI/NavComponent";
import axios from "axios";
import { useSelector } from "react-redux";
import { HomeErr, HomeLoading, SettingsSelector } from "../store/slices/HomeSlice";
import LoadingComponent from "../UI/CustomUI/LoadingComponent";
import { useTranslation } from "react-i18next";
const Contact = () => {
  const CONTACT_URL = process.env.REACT_APP_CONTACT_US_API;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [seenMsg, setSeenMsg] = useState("");
  const settings = useSelector(SettingsSelector);
  const homeLoading = useSelector(HomeLoading);
  const err = useSelector(HomeErr);

  //  FORM DATA OBJ
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    title: '',
    content: ''
  });

  // API FUNC
  const UpdateChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  const SubmitFunc = async (e) => {
    e.preventDefault();
    setLoading(true)
    try {
      await axios.post(CONTACT_URL, formData)
      setSeenMsg(t('msgSent'))
    } catch (err) {
      setSeenMsg(t('msgFail'))
      alert('ERROR : ' + err)
    } finally {
      setLoading(false)
    }
  }
  // LOADING
  if (homeLoading) return <LoadingComponent
    parentClass={`w-full grid grid-cols-[25%_75%] gap-3 p-5 mob:grid-cols-1`}
    childClass={[
      'w-full h-[8rem] rounded-xl tab:h-[22rem] mob:h-[7rem]',
      'w-full h-[27rem] rounded-xl row-span-3 tab:h-[69rem] mob:h-[7rem]',
      'w-full h-[8rem] rounded-xl tab:h-[22rem] mob:h-[7rem]',
      'w-full h-[8rem] rounded-xl tab:h-[22rem] mob:h-[20rem]',
    ]}
    childrenNumber={4}
  />

  // ERROR 
  if (err && !homeLoading) return <div className="f-bold text-center text-2xl text-[#CD1818]">
    <p>{t('tryAgain')}</p>
  </div>
  return (
    <>
      <NavComponent firstName={t('contactUs')} firstURL={`/contact`} />
      <main className="py-10 px-[1.25rem] overflow-hidden mob:px-3">
        <div className="flex flex-col items-center w-fit mx-6 tab:mx-0">
          <h1 className="f-ex-bold text-2xl ">{t('contactUs')}</h1>
          <div><img src="/imgs/curve.png" alt="curve" className="w-auto" /></div>
        </div>
        <section className="flex gap-8 items-start mob:gap-12 mob:flex-col my-12">
          <section>
            <div className={`flex items-center gap-3 p-3 rounded-xl dataBox w-auto`}>
              <div className="bg-[#FB0100] w-14 h-14 rounded-full flex items-center justify-center"><i className="fa-solid fa-map-marker-alt text-white text-2xl"></i></div>
              <div>
                <h2 className="f-bold text-base">{t('address')}</h2>
                <p className="f-rg text-sm text-gray-400 w-[15rem]">{t('exText')}</p>
              </div>
            </div>
            <div className={`flex items-center gap-3 p-3 rounded-xl dataBox my-3`}>
              <div className="bg-[#FB0100] w-14 h-14 rounded-full flex items-center justify-center"><i className="fa-solid fa-phone text-white text-2xl"></i></div>
              <div>
                <h2 className="f-bold text-base">{t('hotline')}</h2>
                <div className="text-gray-400" dir="ltr">
                  <a href={`tel:+${settings?.phone}`} className="f-semi-bold text-sm my-1">+{settings?.phone?.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4')}</a> <br />
                  <a href={`tel:+${settings?.phone}`} className="f-rg text-sm ">+{settings?.phone?.replace(/(\d{3})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4')}</a>
                </div>
              </div>
            </div>
            <div className={`flex items-center gap-3 p-3 rounded-xl dataBox`}>
              <div className="bg-[#FB0100] w-14 h-14 rounded-full flex items-center justify-center"><i className="fa-solid fa-envelope text-white text-2xl"></i></div>
              <div>
                <h2 className="f-bold text-base">{t('email')}</h2>
                <div className="text-gray-400">
                  <a href={`mailto:${settings?.email}`} className="f-semi-bold text-sm text-gray-400 my-1">{settings?.email}</a> <br />
                  <a href={`mailto:${settings?.email}`} className="f-semi-bold text-sm text-gray-400">{settings?.email}</a>
                </div>
              </div>
            </div>
          </section>
          <section className="w-full">
            <h1 className="f-bold">{t('sendMsg')}</h1>
            <p className="f-rg text-gray-400 text-sm">{t('publishMail')}</p>
            <form onSubmit={SubmitFunc}>
              <textarea
                name="content"
                id="content"
                value={formData.content}
                onChange={UpdateChange}
                required
                className="border my-5 p-2 f-rg w-3/4 mob:w-full bg-inherit" cols={32} rows={8}></textarea>
              <div className="flex items-center flex-wrap gap-3 my-4 mob:flex-col">
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={UpdateChange}
                  className="f-rg p-2 rounded-lg border outline-none mob:w-full"
                  placeholder={t('website')}
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={UpdateChange}
                  placeholder={`${t('email')} *`}
                  className="f-rg p-2 rounded-lg border outline-none mob:w-full"
                  required
                />
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={UpdateChange}
                  placeholder={`${t('name')}*`}
                  className="f-rg p-2 rounded-lg border outline-none mob:w-full"
                  required />
              </div>
              <div className="flex items-center">
                <button className="animate-btn text-nowrap" type="submit">
                  {loading ? t('sendingMsg') : t('sendNow')}
                </button>
                <div className="text-lg f-bold px-4">
                  {seenMsg && (seenMsg.includes(t('msgFail')) ? <span className="text-[#CD1818]">{seenMsg} ❌</span> : <span className="text-green-500">{seenMsg} ✅</span>)}
                </div>
              </div>
            </form>
          </section>
        </section>
      </main>
      <div className="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2822.7806761080233!2d-93.29138368446431!3d44.96844997909819!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x52b32b6ee2c87c91%3A0xc20dff2748d2bd92!2sWalker+Art+Center!5e0!3m2!1sen!2sus!4v1514524647889" width="600" height="450" frameBorder="0" allowFullScreen></iframe>
      </div>
    </>
  )
}

export default Contact;