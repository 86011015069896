import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const COURSE_WEEKS_IN_MONTH = process.env.REACT_APP_SUBSCRIBE_COURSE_SHOW_MONTH_URL + `?locale=${localStorage.getItem('i18nextLng')}`
const PACKAGE_WEEKS_IN_MONTH = process.env.REACT_APP_SUBSCRIBE_PACKAGE_SHOW_MONTH_URL + `?locale=${localStorage.getItem('i18nextLng')}`

export const GetWeeksInMonth = createAsyncThunk(
    'MonthDTSlice/GetWeeksInMonth',
    async ({ id, whatItem }, { rejectWithValue }) => {
        try {
            if (whatItem === 'packages') {
                const response = await axios.post(PACKAGE_WEEKS_IN_MONTH, {
                    month_id: id
                })
                return response.data.data
            } else {
                const response = await axios.post(COURSE_WEEKS_IN_MONTH, {
                    month_id: id
                })
                return response.data.data
            }
        } catch (err) {
            return rejectWithValue(err.response?.data?.data || "SORRY , Course Month Data is not Available ")
        }
    }
)

const MonthDTSlice = createSlice({
    name: 'MonthDTSlice',
    initialState: {
        data: [],
        loading: false,
        err: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetWeeksInMonth.pending, (state) => {
                state.loading = true;
                state.err = null
            })
            .addCase(GetWeeksInMonth.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false
            })
            .addCase(GetWeeksInMonth.rejected, (state, action) => {
                state.loading = false;
                state.err = action.payload
            });
    }
})

export const MonthDT = (state) => state.MonthDT.data
export const MonthDTLoading = (state) => state.MonthDT.loading
export const MonthDTErr = (state) => state.MonthDT.err

export default MonthDTSlice.reducer