const RatingComponent = ({ rate }) => {
    const totalStars = 5;
    const filledStars = rate; // yellow stars
    const emptyStars = totalStars - filledStars; // grey stars

    return (
        <div className="flex items-center gap-1">
            {Array(filledStars).fill(0).map((_, i) => (
                <i className="fa-solid fa-star" style={{ color: '#FFD43B' }} key={`filled-${i}`}></i>
            ))}
            {Array(emptyStars).fill(0).map((_, i) => (
                <i className="fa-solid fa-star" style={{ color: '#D3D3D3' }} key={`empty-${i}`}></i>
            ))}
        </div>
    );
};
export default RatingComponent