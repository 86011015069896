import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { SetToken, SetUser } from "../../store/slices/ProfileSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

const LoginHook = () => {
  const LOGIN_API = process.env.REACT_APP_LOGIN_API_URL;
  const [errMsg, setErrMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userDT, setUserDT] = useState({
    email: "",
    password: "",
    rememberMe: false,
  });
  const [loggedInMSG, setLoggedInMSG] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const AddChanges = (e) => {
    const { name, value, type, checked } = e.target;

    setUserDT({
      ...userDT,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const LoginUser = async (email, password) => {
    try {
      const res = await axios.post(
        LOGIN_API,
        { email, password },
        { headers: { "Content-Type": "application/json" } }
      );
      return { data: res.data, done: true, err: null };
    } catch (err) {
      return {
        data: null,
        done: false,
        err:
          err?.response?.data?.data || "False data enterned , please try again",
      };
    }
  };

  const loginFunc = async (e) => {
    e.preventDefault();
    setLoading(true);
    const { data, err } = await LoginUser(userDT.email, userDT.password);
    if (data !== null && data?.api_token) {
      dispatch(SetToken(data?.api_token));
      dispatch(SetUser(data?.data));
      await new Promise((resolve) => setTimeout(resolve, 100));
      window.location.href = "/profile";
    } else {
      setErrMsg(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (Cookies.get("token")) {
      setLoggedInMSG(true);
      const loggedInTimer = setTimeout(() => {
        navigate("/profile");
      }, 4000);
      return () => clearTimeout(loggedInTimer);
    }
  }, [navigate]);

  return { loggedInMSG, t, loginFunc, userDT, AddChanges, loading, errMsg };
};

export default LoginHook;
