import { Link } from "react-router-dom"
import LoadingComponent from "../CustomUI/LoadingComponent"
const SubscriptionsBox = ({ item, WhereLink }) => {
    if (!item) return <LoadingComponent parentClass={`w-full grid p-2`} childClass={`h-[25rem] w-full rounded-lg`} childrenNumber={1} />
    const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
    return (
        <Link to={`/${`subscriptions/${WhereLink}`}/${item?.id}`} className="w-full block max-w-full">
            <div className="flex items-center justify-start my-3 p-2 shadow-sm border rounded-md mob:flex-col gap-4 h-fit w-full max-w-full">
                <div className="h-fit w-[14.5rem] mob:w-full mob:h-full relative">
                    <img src={`${IMG_URL}${item?.image}`} alt={`${WhereLink === null ? 'package' : 'course'}-${item?.id + 1}`} className="w-full h-auto object-cover" />
                </div>
                <div className="w-full">
                    <p className="f-semi-bold text-gray-400 text-sm">تاريخ الاشتراك : {item?.created_at?.split('T')[0]}</p>
                    <h2 className="f-bold text-xl mob:text-sm" dangerouslySetInnerHTML={{ __html: item?.translations?.[0]?.name }} />
                    <div className="w-full bg-[#D9D9D9] h-[2px] rounded-xl my-3">
                        <div className="bg-red-500 h-[5px] rounded-md" style={{ width: `${(item?.months / 12) * 100}%` }}></div>
                    </div>
                    <div className="flex justify-between items-center w-full">
                        <p className="f-rg text-xs text-gray-400 mob:text-lg">{item?.months} شهر</p>
                        <p className="f-rg text-xs text-gray-400 mob:text-lg">12 شهر</p>
                    </div>
                </div>
            </div>
        </Link>

    )
}
export default SubscriptionsBox