import { Link, useNavigate } from "react-router-dom";
// import RatingComponent from "./RatingComponent";
import AddToFavourite from "../../hooks/CustomHooks/SubscribeHooks/AddToFavourite";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const CourseBox = ({ course, className, boxWidth }) => {
  const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
  const [favDone, setFavDone] = useState(false);
  const [favLoading, setFavLoading] = useState(false);
  const [favErr, setFavErr] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const favFunc = async (id, whereAddFavourite) => {
    setFavLoading(true);
    const { done, err } = await AddToFavourite(id, whereAddFavourite);
    setFavDone(done);
    setFavErr(err);
    setFavLoading(false);
  };

  useEffect(() => {
    const seeIfNoUser = async () => {
      if (favErr !== null && !Cookies.get("token")) {
        await new Promise((resolve) => setTimeout(resolve, 300));
        navigate("/login");
      }
    };
    seeIfNoUser();
  }, [favErr, navigate]);

  return (
    <div
      className={`p-4 border-2 max-w-full transition-all duration-300 ease-in-out hover:border-red-600 hover:rounded-3xl overflow-hidden ${
        boxWidth ? boxWidth : "w-[25.25rem]"
      } tab:w-full`}
    >
      <div className="relative">
        <Link to={`/courses/${course.id}`}>
          <div>
            <img
              src={`${IMG_URL}${course?.image}`}
              alt={`course-${course.id}`}
              className={`rounded-lg w-full ${className}`}
            />
          </div>
        </Link>
        <div className="absolute left-4 top-4 cursor-pointer">
          {favErr ? (
            <div className="bg-white w-11 h-11 flex items-center justify-center rounded-lg">
              <p className="text-xl m-0">⛔</p>
            </div>
          ) : favLoading ? (
            <div className="bg-white w-11 h-11 flex items-center justify-center rounded-lg">
              <div className="animate-spin rounded-full border-t-2 border-b-2 border-gray-400 h-7 w-7 z-50"></div>
            </div>
          ) : (
            <>
              {course?.is_favourite ? (
                <img
                  src="/icons/favourite.svg"
                  alt="favorite-icon"
                  title={t("favItem")}
                  className="w-full h-full"
                />
              ) : favDone ? (
                <img
                  src="/icons/favourite.svg"
                  alt="favorite-icon"
                  title={t("favAdded")}
                />
              ) : (
                <img
                  src="/icons/non-favourite.svg"
                  alt="non-favorite-icon"
                  className="z-0"
                  title={t("addFav")}
                  onClick={() => {
                    if (!favLoading) {
                      favFunc(course?.id, "courses");
                    }
                  }}
                />
              )}
            </>
          )}
        </div>
      </div>
      <Link to={`/courses/${course?.id}`}>
        <div className="my-4">
          <h1 className="f-semi-bold mob:text-base w-full whitespace-nowrap overflow-hidden text-ellipsis">
            {course?.translations?.[0]?.name}
          </h1>
          <div
            className="text-gray-500 f-rg my-3 mob:text-sm h-[80px] overflow-hidden"
            dangerouslySetInnerHTML={{
              __html: course?.translations?.[0].desc?.slice(0, 100) + "...",
            }}
          ></div>
        </div>
      </Link>
      <div className="flex items-baseline gap-1">
        <i className="fa-solid fa-star" style={{ color: "#FFD43B" }}></i>
        <p className="f-semi-bold me-2">
          ( {course?.rate} {t("rate")} )
        </p>
      </div>
      <Link to={`/courses/${course?.id}`}>
        <div className="flex justify-between mt-4 mob:flex-col mob:gap-4">
          <button className="animate-btn w-fit">
            <i className="fas fa-play"></i>
            {t("watchNow")}
          </button>
          <div>
            <p className="f-bold">
              {course.price ? `${course.price} ${t("riyal")}` : t("free")}
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default CourseBox;
