import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const COURSE_DETAILS = process.env.REACT_APP_SUBSCRIBE_COURSE_DETAILS_URL + `?locale=${localStorage.getItem('i18nextLng')}`
const PACKAGE_DETAILS = process.env.REACT_APP_SUBSCRIBE_PACKAGE_DETAILS_URL + `?locale=${localStorage.getItem('i18nextLng')}`

// FOR RETURNING HOW MANY MONTH
export const FetchCourseOrPackageDTS = createAsyncThunk(
    'CourseOrPackageDT/FetchCourseOrPackageDTS',
    async ({ id, whatItem }, { rejectWithValue }) => {
        try {
            if (whatItem === 'packages') {
                const response = await axios.post(PACKAGE_DETAILS, {
                    package_id: id
                })
                return response.data.data
            } else {
                const response = await axios.post(COURSE_DETAILS, {
                    course_id: id
                })
                return response.data.data
            }
        } catch (err) {
            return rejectWithValue(err.response?.data?.data || `SORRY , ${whatItem.toUpperCase()} is not Available `)
        }
    }
)

const CourseOrPackageDT = createSlice({
    name: 'CourseOrPackageDT',
    initialState: {
        data: [],
        loading: false,
        err: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(FetchCourseOrPackageDTS.pending, (state) => {
                state.loading = true;
                state.err = null
            })
            .addCase(FetchCourseOrPackageDTS.fulfilled, (state, action) => {

                state.data = action.payload;
                state.loading = false
            })
            .addCase(FetchCourseOrPackageDTS.rejected, (state, action) => {
                state.loading = false;
                state.err = action.payload
            });
    }
})

export const ReturnedDTMonths = (state) => state.CourseOrPackageDT.data;
export const ReturnedDTLoading = (state) => state.CourseOrPackageDT.loading
export const ReturnedDTErr = (state) => state.CourseOrPackageDT.err

export default CourseOrPackageDT.reducer