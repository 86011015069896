import { useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const PaymentHook = () => {
  const Navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.profile.user);
  const handleOpenWindow = (key) => {
    const url = `https://ksa.paymob.com/api/acceptance/iframes/5573?payment_token=${key}`; // URL to open
    const target = "_blank"; // Open in a new tab
    // const features = "height=550";
    window.open(url, target);
  };

  const handlePayment = async (amount, package_id, type) => {
    console.log(package_id);
    if (user) {
      try {
        setLoading(true);
        const orderResponse = await axios.post(
          `https://api.fahadaldulijan.store/api/paymob/initiate`,
          {
            amount: amount, // amount_cents must be equal to the sum of the items amounts
            currency: "SAR",
            payment_methods: [6741],
            user_id: user?.id,
            package_id: package_id,
            type: type,
            items: [],
            billing_data: {
              apartment: "dumy",
              first_name: user?.first_name,
              last_name: user?.last_name,
              street: "dumy",
              building: "dumy",
              phone_number: user?.phone || "dumy",
              city: "dumy",
              country: "dumy",
              email: user?.email || "dumy",
              floor: "dumy",
              state: "dumy",
            },
            callback_url: "http://localhost:3003/packages/payStatus",
          }
        );
        setLoading(false);
        if (orderResponse?.data?.payment_keys?.[0]?.key) {
          handleOpenWindow(orderResponse?.data?.payment_keys?.[0]?.key);
        }
      } catch (error) {
        setLoading(false);
        console.error("Payment error", error);
      }
    } else {
      Navigate("/login");
    }
  };

  return { handlePayment, loading };
};

export default PaymentHook;
