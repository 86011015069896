import { useTranslation } from "react-i18next"
import NavComponent from "../../UI/CustomUI/NavComponent"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react";
import EditProfileHook from "../../hooks/CustomHooks/ProfileHooks/EditProfileHook";
import Cookies from 'js-cookie';

const EditProfile = () => {
    const { t } = useTranslation();
    const [notFound, setNotFound] = useState(false);
    const [editDT, setEditDT] = useState({
        firstName: '',
        lastName: '',
        email: '',
        profileImg: '',
        phone: '',
        rememberMe: false
    })
    const [hasSent, setHasSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [err, setErr] = useState(null);
    const [fileName, setFileName] = useState('');


    const DisplayFileName = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFileName(file.name);
        } else {
            setFileName('');
        }
    };

    const MakeChanges = (e) => {
        const { name, value, type, checked } = e.target;

        setEditDT({
            ...editDT,
            [name]: type === "checkbox" ? checked : value
        });
    }

    const SendChanges = async (e) => {
        e.preventDefault();
        setLoading(true)
        const { done, err } = await EditProfileHook(editDT.firstName, editDT.lastName, editDT.email, editDT.profileImg, editDT.phone)
        setHasSent(done);
        setErr(err?.message);
        setLoading(false);
    }

    useEffect(() => {
        if (Cookies.get('token')) {
            setNotFound(false)
        } else {
            setNotFound(true)
        }
    }, [])

    if (notFound) return (
        <div className="f-bold text-[#CD1818] text-center text-2xl py-24">
            <p>{t('sorryNoUser')}</p>
            <div className="f-bold text-blue-500 underline">
                <Link to={`/login`}>
                    {t('canLoginHere')}
                </Link>
            </div>
        </div>
    );

    return <>
        <NavComponent firstName={t('profile')} firstURL={`/profile`} secName={t('profileSettings')} secURL={`/profile/settings`} />
        <main className="p-6 flex justify-center items-center mob:p-0">
            <section className={`my-22 mx-auto p-4 shadow-md rounded w-2/4 tab:w-full mob:p-2 dataBox`}>
                <div className="flex flex-col items-center w-fit">
                    <h1 className="f-ex-bold text-2xl ">{t('setting')}</h1>
                    <div><img src="/imgs/curve.png" alt="curve" className="w-auto" /></div>
                </div>
                <p className="f-semi-bold mx-3 my-4 text-gray-500 ">{t('editDemo')}</p>
                <form method="POST" onSubmit={SendChanges} >
                    <div className="flex items-center gap-3 px-2 w-full mob:flex-col">
                        <div className="w-full">
                            <label htmlFor="firstName" className="f-bold text-xl">{t('firstName')}*</label> <br />
                            <input
                                type="text"
                                id="firstName"
                                name="firstName"
                                value={editDT.firstName}
                                onChange={MakeChanges}
                                placeholder={t('writeFirst')}
                                className="my-2 border rounded-sm outline-none f-rg px-2 py-1 shadow-sm w-full"
                                required
                                aria-required={true}
                            />
                        </div >
                        <div className="w-full">
                            <label htmlFor="lastName" className="f-bold text-xl">{t('lastName')}*</label> <br />
                            <input
                                type="text"
                                name="lastName"
                                id="lastName"
                                value={editDT.lastName}
                                onChange={MakeChanges}
                                placeholder={t('writeLast')}
                                className="my-2 border rounded-sm outline-none f-rg px-2 py-1 shadow-sm w-full"
                                required
                                aria-required={true}
                            />
                        </div>
                    </div>
                    <div className="px-2">
                        <label htmlFor="email" className="f-bold text-xl">{t('email')}*</label> <br />
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={editDT.email}
                            onChange={MakeChanges}
                            placeholder={t('writeEmail')}
                            className="my-2 border rounded-sm outline-none f-rg px-2 py-1 shadow-sm w-full"
                            required
                            aria-required={true}
                        />
                    </div>
                    <div className="px-2">
                        <label htmlFor="phone" className="f-bold text-xl">{t('phone')}</label> <br />
                        <input
                            type="tel"
                            id="phone"
                            name="phone"
                            value={editDT.phone}
                            onChange={MakeChanges}
                            placeholder={t('writePhone')}
                            className="my-2 border rounded-sm outline-none f-rg px-2 py-1 shadow-sm w-full"
                            dir={localStorage.getItem('i18nextLng') === 'en' ? 'ltr' : 'rtl'}
                        />
                    </div>
                    <div className="px-2 my-2">
                        <input
                            type="file"
                            name="profileImg"
                            value={editDT.profileImg}
                            onChange={(e) => {
                                MakeChanges(e);
                                DisplayFileName(e)
                            }}
                            id="file"
                            accept="image/*"
                            lang={localStorage.getItem('i18nextLng')}
                            className="hidden"
                        />
                        <label
                            htmlFor="file"
                            className="cursor-pointer bg-gray-400 text-white f-bold font-bold px-4 py-2 rounded-md hover:bg-gray-500 transition"
                            aria-labelledby="file"
                        >
                            {t('upload')}
                        </label>
                        {fileName && <p className="mt-2 text-gray-700">{fileName.length > 20 ? `${fileName.slice(0, 16)}...` : fileName}</p>}
                    </div>
                    <div className="my-2 px-2">
                        <input type="checkbox" name="rememberMe" id="remember-me" value={editDT.rememberMe} onChange={MakeChanges} />
                        <label htmlFor="remember-me" className="f-bold text-base mx-2">{t('rememberMe')}</label>
                    </div>
                    <div>
                        <button className="submit-btn text-xl" type="submit">
                            {
                                loading ? (
                                    <div className="bg-white w-11 h-11 flex items-center justify-center rounded-lg">
                                        <div className="animate-spin rounded-full border-t-2 border-b-2 border-black h-7 w-7 z-50"></div>
                                    </div>
                                ) : hasSent ? (
                                    <span className="mx-2 f-bold">{t('updateDone')} </span>
                                ) : (
                                    <>
                                        {localStorage.getItem('i18nextLng') === 'en' ? (
                                            <>
                                                <span className="mx-2 f-bold">{t('update')}</span>
                                                <i className="fa-solid fa-arrow-right"></i>
                                            </>
                                        ) : (
                                            <>
                                                <span className="mx-2 f-bold">{t('update')}</span>
                                                <i className="fa-solid fa-arrow-left"></i>
                                            </>
                                        )}
                                    </>
                                )
                            }
                        </button>

                    </div>
                </form>
                <div className="text-center mt-4">
                    <span className="text-gray-500 f-semi-bold">{t('doUHaveProblem')}</span>
                    <span className="text-[#CD1818]" style={{ textDecoration: 'underline' }}>
                        <Link to={`/contact`} className="f-bold mx-2" >
                            {t('contactUs')}
                        </Link>
                    </span>
                </div>
            </section>
            {err && <div className="text-base text-center my-3 text-[#CD1818] font-medium">{err}</div>}
        </main>
    </>
}
export default EditProfile