import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const CHOOSED_COURSES = process.env.REACT_APP_CHOOSED_COURSE_API_URL.replace('locale=', `locale=${localStorage.getItem('i18nextLng')}`)
const ALL_CATEGORIES = process.env.REACT_APP_ALL_CATEGORIES_API + `?locale=${localStorage.getItem('i18nextLng')}`

// GETTING CATEGORIES FETCHING
export const GettingCategories = createAsyncThunk(
    'ChoosedDTSlice/GettingCategories',
    async (_, { rejectWithValue }) => {
        try {
            const response = await axios.get(ALL_CATEGORIES);
            return response?.data?.data;
        } catch (err) {
            return rejectWithValue(err?.response?.data?.message || 'Error fetching categories');
        }
    }
);

// CHOOSED DATA FETCHING 
export const GetChoosedDT = createAsyncThunk(
    'ChoosedDTSlice/GetChoosedDT',
    async ({ category_id, rate, price, locale }, { rejectWithValue }) => {
        try {
            const res = await axios.get(CHOOSED_COURSES, {
                category_id,
                rate,
                price,
                locale,
            });
            return res.data.data;
        } catch (err) {
            return rejectWithValue(err?.response?.data?.data || 'Error in fetching choosed data');
        }
    }
);

const ChoosedDTSlice = createSlice({
    name: 'ChoosedDTSlice',
    initialState: {
        data: [],
        category: 0,
        rate: 0,
        price: 0,
        lang: '',
        loading: false,
        error: null,
        categories: [],
        categoriesLoading: false,
        categoriesErr: null
    },
    reducers: {
        GetRate: (state, action) => {
            state.rate = (action.payload)
        },
        GetPrice: (state, action) => {
            state.price = (action.payload);
        },
        GetCategory: (state, action) => {
            state.category = (action.payload);
        },
        GetLang: (state, action) => {
            state.lang = action.payload;
        },
        ResetChoosedDT: (state) => {
            state.category = null;
            state.rate = null;
            state.price = null;
            state.lang = null;
        }
    },
    extraReducers: (builder) => {
        builder
            // CHOOSED DT
            .addCase(GetChoosedDT.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(GetChoosedDT.fulfilled, (state, action) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(GetChoosedDT.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // CATEGORIES DT
            .addCase(GettingCategories.pending, (state) => {
                state.categoriesLoading = true;
                state.categoriesErr = null;
            })
            .addCase(GettingCategories.fulfilled, (state, action) => {
                state.categoriesLoading = false;
                state.categories = action.payload;
            })
            .addCase(GettingCategories.rejected, (state, action) => {
                state.categoriesLoading = false;
                state.categoriesErr = action.payload;
            });
    }
});

export const { GetRate, GetPrice, GetCategory, GetLang, ResetChoosedDT } = ChoosedDTSlice.actions;

// CHOOSED DT SELECTORS
export const ChoosedData = (state) => state.ChoosedDT.data;
export const CategorySelector = (state) => state.ChoosedDT.category;
export const RateSelector = (state) => state.ChoosedDT.rate;
export const PriceSelector = (state) => state.ChoosedDT.price;
export const LangSelector = (state) => state.ChoosedDT.lang;
export const ChoosedDTLoading = (state) => state.ChoosedDT.loading;
export const ChoosedDTErr = (state) => state.ChoosedDT.error;

// CATEGORIES DT SELECTORS
export const CategoriesSelector = (state) => state.ChoosedDT.categories;
export const CategoriesLoading = (state) => state.ChoosedDT.categoriesLoading;
export const CategoriesErr = (state) => state.ChoosedDT.categoriesErr;

export default ChoosedDTSlice.reducer;
