import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AddToFavourite from "../../hooks/CustomHooks/SubscribeHooks/AddToFavourite";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const PackageBox = ({
  pack,
  boxClassName,
  seeFavourite,
  slidesPerView,
  imgClass,
  mainBox,
}) => {
  const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
  const [favDone, setFavDone] = useState(false);
  const [favLoading, setFavLoading] = useState(false);
  const [favErr, setFavErr] = useState(null);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const favFunc = async (id, whereAddFavourite) => {
    setFavLoading(true);
    const { done, err } = await AddToFavourite(id, whereAddFavourite);
    setFavDone(done);
    setFavErr(err);
    setFavLoading(false);
  };

  useEffect(() => {
    const seeIfNoUser = async () => {
      if (favErr !== null && !Cookies.get("token")) {
        await new Promise((resolve) => setTimeout(resolve, 300));
        navigate("/login");
      }
    };
    seeIfNoUser();
  }, [favErr, navigate]);

  return (
    <div
      className={`flex gap-4 relative mx-3 max-w-full border-l border-black ${
        mainBox ? mainBox : "w-full"
      }`}
    >
      <div className={`${boxClassName} cursor-pointer flex-1`}>
        <div className="img-box relative">
          <Link to={`/packages/${pack?.id}`} className="h-[300px] block">
            <img
              src={`${IMG_URL}${pack?.image}`}
              alt={`${pack?.months}-month`}
              className={`rounded-lg ${imgClass} object-cover h-full w-full`}
            />
          </Link>
          <div className="absolute left-4 top-4 ">
            {seeFavourite && (
              <>
                {favErr ? (
                  <div className="bg-white w-11 h-11 flex items-center justify-center rounded-lg">
                    <p className="text-xl m-0">⛔</p>
                  </div>
                ) : favLoading ? (
                  <div className="bg-white w-11 h-11 flex items-center justify-center rounded-lg">
                    <div className="animate-spin rounded-full border-t-2 border-b-2 border-gray-400 h-7 w-7 z-50"></div>
                  </div>
                ) : (
                  <>
                    {pack?.is_favourite ? (
                      <img
                        src="/icons/favourite.svg"
                        alt="favourite-icon"
                        title={t("favItem")}
                        className="w-full h-full"
                      />
                    ) : favDone ? (
                      <img
                        src="/icons/favourite.svg"
                        alt="favourite-icon"
                        title={t("favAdded")}
                      />
                    ) : (
                      <img
                        src="/icons/non-favourite.svg"
                        alt="non-favourite-icon"
                        className="z-0"
                        title={t("addFav")}
                        onClick={() => {
                          if (!favLoading) {
                            favFunc(pack?.id, "packages");
                          }
                        }}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <Link to={`/packages/${pack?.id}`}>
          <div className={`my-2 grid ${seeFavourite ? "gap-6" : "gap-12"}`}>
            <div>
              <div>
                <span className="text-lg f-bold text-[#CD1818]">
                  {pack?.price_after} {t("riyal")}
                </span>
                <del className="text-lg f-rg text-gray-500 mx-2">
                  {pack?.price_before} {t("riyal")}
                </del>
              </div>
              <div>
                <p
                  className="f-bold text-2xl text-wrap w-80"
                  dangerouslySetInnerHTML={{
                    __html: pack?.translations?.[0]?.desc,
                  }}
                />
              </div>
            </div>
            <div>
              <div>
                <i
                  className="fa-solid fa-star"
                  style={{ color: "#FFD43B" }}
                ></i>
                <span className="f-semi-bold text-gray-500 mx-2">
                  ( {pack?.rate} {t("rate")} )
                </span>
              </div>
              <hr className="my-2" />
              <div>
                <button className="f-semi-bold">
                  {localStorage.getItem("i18nextLng") === "en"
                    ? `${t("viewDetails")} \u2192`
                    : `\u2190 ${t("viewDetails")}`}
                </button>
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="w-2 h-2 absolute bottom-[1px] left-[-4px] rotate-[135deg] square"></div>
      {/* <div className="flex items-stretch mob:hidden">
        {slidesPerView < 3 ? (
          <div className="relative flex">
            <div className="w-[2px] h-full bg-gray-400"></div>
            <div className="w-2 h-2 bg-gray-400 absolute -bottom-1 -left-[2.7px] rotate-[135deg]"></div>
          </div>
        ) : (
          <div className="relative flex">
            <div className="w-[1px] h-full long-line"></div>
            <div className="w-2 h-2 absolute -bottom-1 -left-[3px] rotate-[135deg] square"></div>
          </div>
        )}
      </div> */}
    </div>
  );
};
export default PackageBox;
