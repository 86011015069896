import { configureStore } from "@reduxjs/toolkit";
import HomeReducer from "./slices/HomeSlice";
import ProfileReducer from "./slices/ProfileSlice";
import ItemDTReducer from "./slices/ItemDTSlice";
import PackageDTReducer from "./slices/PackageDTSlice";
import CourseOrPackageDTReducer from "./slices/SubscriptionSlices/CourseOrPackageDT";
import MonthDTReducer from "./slices/SubscriptionSlices/MonthDTSlice";
import WeekDTReducer from "./slices/SubscriptionSlices/WeekDTSlice";
import ChoosedDTReducer from "./slices/ChoosedDTSlice";
import ForgetPassReducer from './slices/ForgetPassSlice'
const store = configureStore({
    reducer: {
        home: HomeReducer,
        profile: ProfileReducer,
        ItemDT: ItemDTReducer,
        packageDT: PackageDTReducer,
        CourseOrPackageDT: CourseOrPackageDTReducer,
        MonthDT: MonthDTReducer,
        WeekDT: WeekDTReducer,
        ChoosedDT: ChoosedDTReducer,
        ForgetPass: ForgetPassReducer
    }
})

export default store