import axios from "axios";
const CreateAccount = async (first_name, last_name, email, password) => {
    const REGISTER_URL = process.env.REACT_APP_REGISTER_API_URL;
    try {
        const response = await axios.post(REGISTER_URL, {
            first_name,
            last_name,
            email,
            password
        });

        if (response.status === 200 && response.data.msg === "success") {
            return response.data;
        } else {
            throw new Error(`Register Failed: ${response?.data?.msg}`);
        }
    } catch (error) {
        throw new Error(error?.response?.data?.data?.email?.[0] || 'Register Failed, Try Again');
    }
};

export default CreateAccount