import { NavLink, Link } from "react-router-dom";
import NavbarHook from "../../hooks/NavbarHook";

const NavBar = () => {
  const {seeMenu,toggleSeeMenu,isEnglish,seeProfileDT,t,changeLang,theme,lang,makeTheme,closeMenuOnNavigate} = NavbarHook()

  return (
    <header className="py-6 px-4 shadow-sm relative overflow-hidden h-[100px]">
      {seeMenu && (
        <div
          className="fixed inset-0 bg-[#000000db] z-20"
          onClick={toggleSeeMenu}
        />
      )}
      <nav className="flex items-center justify-between gap-8">
        <div className="w-64 mx-3 tab:mx-0 tab:w-auto">
          <Link to={`/`}>
            <h1 className="f-ex-bold flex items-center text-2xl" dir={isEnglish ? 'rtl' : 'ltr'}>
              <div className="text-nowrap">
                <span className="text-[#CD1818]">DULIJAN </span>
                ACADEMY
              </div>
              <span>
                <img src="/icons/more-vertical.svg" alt="More options" className="w-7" />
              </span>
            </h1>
          </Link>
        </div>
        <aside
          className={`block relative tab:h-dvh tab:fixed top-0 z-30 w-2/4 tab:py-8 mob:w-full transition-all duration-300 ${seeMenu ? "tab:left-0" : "tab:-left-[100rem]"
            } tab:overflow-y-scroll nav`}
        >
          <ul className="flex items-center gap-8 tab:flex-col p-0">
            <li>
              <NavLink to={`/`} className={`text-nowrap relative text-md lg:text-lg xl:text-xl link ${isEnglish ? 'font-bold' : 'f-bold'}`} onClick={closeMenuOnNavigate}>
                {t('home')}
              </NavLink>
            </li>

            {seeProfileDT && (
              <li>
                <NavLink to={`/subscriptions`} className={`text-nowrap relative text-md lg:text-lg xl:text-xl link ${isEnglish ? 'font-bold' : 'f-bold'}`} onClick={closeMenuOnNavigate}>
                  {t('subscriptions')}
                </NavLink>
              </li>
            )}
            <li>
              <NavLink to={`/courses`} className={`text-nowrap relative text-md lg:text-lg xl:text-xl link ${isEnglish ? 'font-bold' : 'f-bold'}`} onClick={closeMenuOnNavigate}>
                {t('courses')}
              </NavLink>
            </li>
            <li>
              <NavLink to={`/packages`} className={`text-nowrap relative text-md lg:text-lg xl:text-xl link ${isEnglish ? 'font-bold' : 'f-bold'}`} onClick={closeMenuOnNavigate}>
                {t('packages')}
              </NavLink>
            </li>
            <li>
              <NavLink to={`/contact`} className={`text-nowrap relative text-md lg:text-lg xl:text-xl link ${isEnglish ? 'font-bold' : 'f-bold'}`} onClick={closeMenuOnNavigate}>
                {t('contact')}
              </NavLink>
            </li>
            <li>
              <NavLink to={`/about`} className={`text-nowrap relative text-md lg:text-lg xl:text-xl link ${isEnglish ? 'font-bold' : 'f-bold'}`} onClick={closeMenuOnNavigate}>
                {t('about')}
              </NavLink>
            </li>
            {seeProfileDT ? (
              <li>
                <NavLink to={`/profile`} className={`text-md lg:text-lg xl:text-xl text-nowrap relative link hidden tab:block ${isEnglish ? 'font-bold' : 'f-bold'}`} onClick={closeMenuOnNavigate}>
                  {t('profile')}
                </NavLink>
              </li>
            ) : (
              <li>
                <NavLink to={`/login`} className={`text-md lg:text-lg xl:text-xl text-nowrap relative link hidden tab:block ${isEnglish ? 'font-bold' : 'f-bold'}`} onClick={closeMenuOnNavigate}>
                  {t('login')}
                </NavLink>
              </li>
            )}
            <li>
              <div className="f-bold gap-4 items-center hidden tab:flex">
                <button className={`${lang === 'ar' ? 'opacity-100' : 'opacity-50'}`} onClick={() => changeLang('ar')}>
                  {t('ar')}
                </button>
                <span className="bg-gray-300 w-[1px] h-6"></span>
                <button className={`${lang === 'en' ? 'opacity-100' : 'opacity-50'}`} onClick={() => changeLang('en')}>
                  {t('en')}
                </button>
              </div>
            </li>
            <li>
              <div className="f-bold gap-4 items-center hidden tab:flex">
                <button className={`${theme === 'dark' ? 'opacity-100' : 'opacity-50'}`} onClick={() => makeTheme('dark')}>
                  {t('dark')}
                </button>
                <span className="bg-gray-300 w-[1px] h-6"></span>
                <button className={`${theme === 'light' ? 'opacity-100' : 'opacity-50'}`} onClick={() => makeTheme('light')}>
                  {t('light')}
                </button>
              </div>
            </li>
          </ul>
          <span className="absolute top-4 right-7 cursor-pointer hidden tab:block" onClick={toggleSeeMenu}>
            <i className="fas fa-times text-2xl text-[#CD1818]"></i>
          </span>
        </aside>
        <div>
          <ul className="flex items-center gap-2 p-0 tab:hidden">
            {seeProfileDT ? (
              <li>
                <NavLink to={`/profile`}>
                  <span className={`rounded-full border-2 w-10 h-10 flex justify-center items-center ${theme === 'dark' ? 'border-[#555]' : 'border-black'}`}>
                    <i className="fas fa-user-alt fa-lg text-[#CD1818]"></i>
                  </span>
                </NavLink>
              </li>
            ) : (
              <li>
                <NavLink to={`/login`} className={`text-xl text-nowrap relative link ${isEnglish ? 'font-bold' : 'f-bold'}`} onClick={closeMenuOnNavigate}>
                  {t('login')}
                </NavLink>
              </li>
            )}
            <li className="w-10 cursor-pointer">
              {lang === 'ar' ? (
                <img src="/icons/en.svg" alt="Arabic language icon" className="rounded-full border-black border w-full" title={t('en')} onClick={() => changeLang('en')} />
              ) : (
                <img src="/icons/ar.svg" alt="English language icon" className="rounded-full border-black border w-full" title={t('ar')} onClick={() => changeLang('ar')} />
              )}
            </li>
            <li>
              {theme === 'dark' ? (
                <div onClick={() => makeTheme('light')} title={t('light')}><i className="fa-regular fa-sun fa-2xl cursor-pointer"></i></div>
              ) : (
                <div onClick={() => makeTheme('dark')} title={t('dark')}><i className="fa-regular fa-moon fa-2xl cursor-pointer"></i></div>
              )}
            </li>
          </ul>
        </div>
        <div className="hidden tab:block cursor-pointer" onClick={toggleSeeMenu}>
          <i className="fas fa-bars" style={{ fontSize: "2rem" }}></i>
        </div>
      </nav>
    </header>
  );
};

export default NavBar; 