import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from 'js-cookie'

const SEND_VERIFY_CODE = process.env.REACT_APP_SEND_VERIFY_FORGET_PASS
const VERIFIED_CODE = process.env.REACT_APP_VERIFY_FORGET_PASS
const RECHANGE_PASS = process.env.REACT_APP_RECHANGE_PASS;

export const SendVerificationCode = createAsyncThunk(
    'ForgetPassSlice/SendVerificationCode',
    async (mail, { rejectWithValue }) => {
        try {
            const response = await axios.post(SEND_VERIFY_CODE, {
                email: mail
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err?.data?.msg || 'Please , Try Again')
        }
    }
);

export const GetVerificationCode = createAsyncThunk(
    'ForgetPassSlice/GetVerificationCode',
    async ({ veriCode, veriMail }, { rejectWithValue }) => {
        try {
            const verifiedCodeResponse = await axios.post(VERIFIED_CODE, {
                email: veriMail,
                code: veriCode
            })
            return verifiedCodeResponse.data
        } catch (error) {
            return rejectWithValue(error?.data || 'Please , Try Again')
        }
    }
);

export const RechangePass = createAsyncThunk(
    'ForgetPassSlice/RechangePass',
    async ({ lastMail, newPass, confPass, veriCode }, { rejectWithValue }) => {
        try {
            const res = await axios.post(RECHANGE_PASS, {
                email: lastMail,
                new_pass: newPass,
                confirm_pass: confPass,
                code: veriCode
            })
            return res.data
        } catch (err) {
            rejectWithValue(err?.data || 'Check Your Password')
        }
    }
);

const ForgetPassSlice = createSlice({
    name: 'ForgetPassSlice',
    initialState: {
        verifyNumber: null,
        tokenEmail: null,
        loading: false,

        codeLoading: false,
        haveAccess: false,
        verifyErr: null,

        newPass: null,
        confirmPass: null,
        newPassLoading: false,
        newPassErr: null,
    },
    extraReducers: (builder) => {
        builder
            // FIRST : SEND VERIFICATION CODE
            .addCase(SendVerificationCode.pending, (state) => {
                state.loading = true;
                state.verifyErr = null;
            })
            .addCase(SendVerificationCode.fulfilled, (state, action) => {
                const { verifynumber, data } = action.payload;
                state.verifyNumber = verifynumber;
                state.tokenEmail = data.email;
                state.loading = false;
            })
            .addCase(SendVerificationCode.rejected, (state, action) => {
                state.loading = false;
                state.verifyErr = action.payload;
            })

            // SEC : CHECK THE VERIFICATION CODE
            .addCase(GetVerificationCode.pending, (state) => {
                state.codeLoading = true;
                state.verifyErr = null;
            })
            .addCase(GetVerificationCode.fulfilled, (state) => {
                state.haveAccess = true;
                state.codeLoading = false;
            })
            .addCase(GetVerificationCode.rejected, (state, action) => {
                state.codeLoading = false;
                state.haveAccess = false;
                state.verifyErr = action.payload;
            })

            // THIRD : RECHANGING PASS AND SAVE TOKEN
            .addCase(RechangePass.pending, (state) => {
                state.newPassLoading = true;
                state.newPassErr = null;
            })
            .addCase(RechangePass.fulfilled, (state, action) => {
                const { api_token } = action.payload;
                Cookies.set('token', api_token)
                state.newPassLoading = false;
            })
            .addCase(RechangePass.rejected, (state, action) => {
                state.newPassLoading = false;
                state.newPassErr = action.payload;
            })
    },
})

export const VeriLoading = (state) => state.ForgetPass.loading;
export const VeriCode = (state) => state.ForgetPass.verifyNumber;
export const VeriErr = (state) => state.ForgetPass.verifyErr;

export const Access = (state) => state.ForgetPass.haveAccess;
export const Mail = (state) => state.ForgetPass.tokenEmail;
export const CodeLoading = (state) => state.ForgetPass.codeLoading;

export const newPassLoading = (state) => state.ForgetPass.newPassLoading;
export const newPassErr = (state) => state.ForgetPass.newPassErr;

export default ForgetPassSlice.reducer