import PackageSwiper from "./packageSwiper";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./home.css";
const LandingContent = () => {
  const { t } = useTranslation();
  return (
    <section className="landing w-full p-3 pb-0 lg:px-20 mt-6 overflow-hidden tab:h-auto">
      <main className="flex flex-wrap my-5 mx-2">
        <div className="w-[55%] px-4 tab:w-full">
          <div>
            <h3
              className={`${
                localStorage.getItem("i18nextLng") === "en"
                  ? "font-bold"
                  : "f-semi-bold"
              } text-xl`}
            >
              {t("landing.0")}
            </h3>
            <h1 className="heroTxt">
              <span className="f-black relative z-10 text-7xl mob:text-[2.9rem]">
                {t("landing.1")}
              </span>
              <span className={`heroBg bg-[#ffc224]`}></span>
            </h1>
            <h2
              className={`text-4xl ${
                localStorage.getItem("i18nextLng") === "en"
                  ? "font-bold"
                  : "f-ex-bold"
              }`}
            >
              {t("landing.2")}
            </h2>
          </div>
          <div className="my-4">
            <p
              className={`text-xl w-[31rem] tab:w-auto ${
                localStorage.getItem("i18nextLng") === "en"
                  ? "font-medium"
                  : "f-semi-bold "
              }`}
            >
              {t("landing.3")}
            </p>
          </div>
          <div className="red-line"></div>
          <div className="my-4">
            <PackageSwiper
              boxClassName={`swiper-box-landing`}
              oneSlide={true}
              seeFavourite={false}
              swiperClassName={`w-full h-60 tab:h-60 mob:h-full`}
              imgClass={`h-[12.5rem] w-[15rem] tab:h-[19rem] tab:object-center mob:h-[23rem] tab:w-full`}
            />
          </div>
        </div>
        <div className="w-[45%] px-4 relative tab:hidden">
          <div className="relative -top-28 z-20">
            <img src="/imgs/Shape 1 2.png" alt="lift-man" />
          </div>
          <div>
            <img
              src="/imgs/Rectangle 4.png"
              alt="rectangle"
              className="red-bg"
            />
          </div>
          <div>
            <img
              src="/imgs/banner_shape02.png.png"
              alt="banner"
              className="banner"
            />
          </div>
          <div className={`black-border-box border-2`}></div>
          <div className="curved-line">
            <div>
              <img src="/imgs/Vector.png" alt="curve-line" />
            </div>
            <div>
              <img src="/imgs/Intersect.png" alt="curve-line" />
            </div>
          </div>
          <div>
            <img src="/imgs/x-icon.png" alt="x-icon" className="x-icon" />
          </div>
          <div>
            <img src="/imgs/Frame.png" alt="frame" className="frame" />
          </div>
          <div>
            <img src="/imgs/x-icon2.png" alt="x-icon" className="x-icon-down" />
          </div>
          <div>
            <img src="/imgs/x-icon.png" alt="x-icon" className="x-icon-up" />
          </div>
          <Link to={`/courses`} className="our-courses z-50">
            <div className="flex flex-col items-center w-fit">
              <div>
                <img src="/imgs/our-courses.png" alt="our-courses" />
              </div>
              <p
                className={`text-sm ${
                  localStorage.getItem("i18nextLng") === "en"
                    ? "font-normal"
                    : "f-semi-bold flex-row-reverse"
                } flex `}
              >
                <span>{t("aboutCourses")}</span>
                <i className={`mx-1 my-1 fas fa-arrow-right`}></i>
              </p>
            </div>
          </Link>
          <div className={`comunity-box`}>
            <div>
              <img src="/imgs/communities.png" alt="communities" />
            </div>
            <div>
              <p className="text-gray-500 f-semi-bold my-0">{t("nowSubsc")}</p>
              <p className="f-bold text-2xl my-0 text-black">15K</p>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
};

export default LandingContent;
