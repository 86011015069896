import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
const EmptySubscriptions = () => {
    const { t } = useTranslation();
    return (
        <div className={`border shadow-sm py-5 grid gap-4 rounded-lg justify-items-center`}>
            <div className={`grid justify-items-center p-3 rounded-xl dataBox`}>
                <div><img src="/imgs/no-subscriptions.svg" alt="no-subscriptions" /></div>
                <p className="f-bold text-center">{t('noSubsc')}</p>
            </div>
            <div className="text-[#CD1818]">
                <Link to={`/packages`} className="f-bold" >
                    {t('subscribeNow')}
                </Link>
            </div>
        </div>
    )
}
export default EmptySubscriptions