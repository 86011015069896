import { Link, useParams } from "react-router-dom";
import SubscriptionVideoBox from "../../UI/SubscriptionsUI/SubscriptionVideoBox";
import { useEffect, useState } from "react";
import NavComponent from "../../UI/CustomUI/NavComponent";
import AsideSubscription from "./AsideComponents/AsideSubscription";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../UI/CustomUI/LoadingComponent";
import {
  ErrorItemDetails,
  LoadingItemDetails,
  NowItem,
  NowItemRelatedCourses,
  SubscriptionItemDT,
} from "../../store/slices/ItemDTSlice";
import { ReturnedDTErr } from "../../store/slices/SubscriptionSlices/CourseOrPackageDT";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";

const SubscriptionPageDetails = () => {
  const { t } = useTranslation();
  const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
  const params = useParams();
  const dispatch = useDispatch();
  const [seeSideBar, setSeeSideBar] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const seeBar = () => {
    setSeeSideBar((prevBool) => !prevBool);
  };
  const closeSideBarOnNavigate = () => {
    setSeeSideBar(false);
  };

  useEffect(() => {
    if (Cookies.get("token")) {
      dispatch(
        SubscriptionItemDT({
          id: params.subscriptionId,
          whatItem: params.whatItem,
        })
      );
      setNotFound(false);
    } else {
      setNotFound(true);
    }
  }, [dispatch, params.subscriptionId, params.whatItem]);

  // courses data
  const whoseItem = useSelector(NowItem);
  const relatedCourses = useSelector(NowItemRelatedCourses);
  const loading = useSelector(LoadingItemDetails);
  const courseError = useSelector(ErrorItemDetails);
  const courseDetailsErr = useSelector(ReturnedDTErr);

  // ANY ERRORS
  if (!loading && courseError && courseDetailsErr)
    return (
      <div className="text-2xl text-center m-auto pt-24">
        <span className="font-bold">404 | ERROR</span>
        <p className="my-5 f-bold">{t("sorryNoDT")}</p>
      </div>
    );

  // LOADING
  if (!relatedCourses && loading)
    return (
      <LoadingComponent
        parentClass={`grid grid-cols-1 gap-4 p-2`}
        childClass={`h-[28rem]`}
        childrenNumber={5}
      />
    );

  if (notFound)
    return (
      <div className="f-bold text-[#CD1818] text-center text-2xl py-24">
        <p>{t("sorryNoUser")}</p>
        <div className="f-bold text-blue-500 underline">
          <Link to={`/login`}>{t("canLoginHere")}</Link>
        </div>
      </div>
    );

  return (
    <>
      <NavComponent
        firstName={t("packages")}
        firstURL={`/packages`}
        secName={`${t("packDemo")} ${t("week")} 1`}
      />
      <main className="flex gap-12 p-6 px-10 mob:gap-5 mob:grid mob:p-3 items-start my-12 min-h-screen">
        <AsideSubscription
          seeBar={seeBar}
          seeSideBar={seeSideBar}
          whoseItem={whoseItem}
          closeSideBarOnNavigate={closeSideBarOnNavigate}
          loading={loading}
          error={courseDetailsErr}
        />
        <section
          className={`p-6 tab:p-0 transition-filter duration-300 w-full ${
            seeSideBar ? "filter blur-sm" : ""
          }`}
        >
          <div>
            <h3 className="f-bold text-2xl my-3 mob:text-base">
              {t("packDemo")}( <span className="text-[#CD1818]">1 </span>
              {t("week")} )
            </h3>
            <div>
              {whoseItem?.video ? (
                <video
                  controls
                  src={`${IMG_URL}${whoseItem?.video}`}
                  poster={`${IMG_URL}${whoseItem?.image}`}
                  className="rounded w-full h-[350px] md:h-[450px] lg:h-[600px]"
                >
                  <source
                    src={`${IMG_URL}${whoseItem?.video}`}
                    type="video/mp4"
                  />
                  <source
                    src={`${IMG_URL}${whoseItem?.video}`}
                    type="video/webm"
                  />
                  <source
                    src={`${IMG_URL}${whoseItem?.video}`}
                    type="video/ogg"
                  />
                  {/*  لو المتصفح مبيدعمش */}
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img
                  src={`${IMG_URL}${whoseItem?.image}`}
                  alt={
                    whoseItem?.translations
                      ? whoseItem?.translations[0].name
                      : t("courseName")
                  }
                />
              )}
            </div>
            <div className="flex justify-between items-start my-3 tab:flex-col tab:items-start">
              <div>
                <h2 className="f-bold">
                  {whoseItem?.translations
                    ? whoseItem?.translations[0].name
                    : t("courseName")}
                </h2>
                <p
                  className="f-bold mob:text-base w-8/12 tab:w-auto"
                  dangerouslySetInnerHTML={{
                    __html: whoseItem?.translations
                      ? whoseItem?.translations[0].desc
                      : "وصف الكورس",
                  }}
                />
              </div>
              <div
                style={{
                  textDecoration: "underline",
                  textDecorationColor: "red",
                }}
                className="f-bold text-lg"
              >
                ( {whoseItem?.id} ) :{" "}
                <span className="text-gray-400 f-bold text-lg">
                  ( {relatedCourses?.length + 1} )
                </span>
              </div>
            </div>
          </div>
          <hr />
          <section className="p-0">
            {relatedCourses?.map((course) => (
              <Link key={course?.id} to={`/courses/${course?.id}`}>
                <SubscriptionVideoBox
                  item={course}
                  index={course?.id}
                  totalLength={relatedCourses?.length}
                />
              </Link>
            ))}
          </section>
        </section>
      </main>
    </>
  );
};
export default SubscriptionPageDetails;
