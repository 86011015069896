import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const SHOW_PACKAGE = process.env.REACT_APP_SHOW_PACKAGE_API_URL + `?locale=${localStorage.getItem('i18nextLng')}`

export const ShowPackageDetails = createAsyncThunk(
    'PackageDTSlice/ShowPackageDetails',
    async (id, { rejectWithValue }) => {
        try {
            const response = await axios.post(SHOW_PACKAGE, {
                package_id: id
            })
            return response.data
        } catch (err) {
            return rejectWithValue(err.response?.data?.data || "SORRY , Package is not Available ")
        }
    }
)

const PackageDTSlice = createSlice({
    name: 'PackageDTSlice',
    initialState: {
        data: [],
        loading: false,
        err: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(ShowPackageDetails.pending, (state) => {
                state.loading = true;
                state.err = null
            })
            .addCase(ShowPackageDetails.fulfilled, (state, action) => {
                const { data } = action.payload;
                state.data = data;
                state.loading = false
            })
            .addCase(ShowPackageDetails.rejected, (state, action) => {
                state.loading = false;
                state.err = action.payload
            });
    }
})

export const PackageDT = (state) => state.packageDT.data
export const PackageDTLoading = (state) => state.packageDT.loading
export const PackageDTErr = (state) => state.packageDT.err

export default PackageDTSlice.reducer