const SubscriptionVideoBox = ({ item, index, totalLength }) => {
  const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
  return (
    <div className="flex flex-col sm:flex-row items-start gap-4 border p-3 rounded-xl my-3">
      <img
        src={`${IMG_URL}${item?.image}`}
        alt={`item-${index}`}
        className="w-full sm:w-[150px] md:w-[300px] h-[170px] flex-shrink-0 rounded-2xl"
      />
      <div className="p-2">
        <div
          className="f-semi-bold text-gray-400 text-xs md:text-xl mob:text-base"
          dangerouslySetInnerHTML={{
            __html: item?.translations?.[0]?.desc?.slice(0, 100) + "...",
          }}
        />
        <div
          style={{ textDecoration: "underline", textDecorationColor: "red" }}
          className="f-bold"
        >
          ( {index} ) :{" "}
          <span className="text-gray-400 f-bold">( {totalLength} )</span>
        </div>
      </div>
    </div>
  );
};
export default SubscriptionVideoBox;
