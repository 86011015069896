import { useTranslation } from "react-i18next";
import LoadingComponent from "../../../UI/CustomUI/LoadingComponent";
import AsideSubscriptionContent from "./AsideSubscriptionContent";

const AsideSubscription = ({
  whoseItem,
  seeSideBar,
  seeBar,
  closeSideBarOnNavigate,
  loading,
  error,
}) => {
  const { t } = useTranslation();
  if (loading)
    return (
      <LoadingComponent
        parentClass={`grid grid-cols-1 py-2`}
        childClass={`h-[20rem]`}
        childrenNumber={2}
      />
    );

  if (!whoseItem && error)
    return (
      <div className="f-bold text-xl text-[#CD1818] pt-10 mob:pt-0">
        {t("sorryNoDT")}
      </div>
    );
  return (
    <>
      {seeSideBar && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-10"
          onClick={seeBar}
        />
      )}
      <div className="absolute border rounded-xl top-24 hidden tab:block">
        <button className="f-bold py-2 px-4" onClick={seeBar}>
          {t("menu")}
        </button>
      </div>
      <aside className={`grid w-3/12 p-2 tab:hidden`}>
        <AsideSubscriptionContent whoseItem={whoseItem} />
      </aside>
      <aside
        className={`h-full overflow-y-scroll absolute mob:w-full tab:w-2/4 px-4 z-[1000] top-0 transition-all duration-150 ${
          localStorage.getItem("theme") === "dark" ? "bg-[#252525]" : "bg-white"
        }
                ${
                  localStorage.getItem("i18nextLng") === "en"
                    ? seeSideBar
                      ? "right-0"
                      : "right-[200rem]"
                    : seeSideBar
                    ? "right-0"
                    : "-right-[200rem]"
                }`}
      >
        <div
          className={`f-bold relative top-5 mb-20 cursor-pointer mob:block ${
            localStorage.getItem("i18nextLng") === "en"
              ? "left-[95%]"
              : "-left-[95%]"
          }`}
          onClick={seeBar}
        >
          <i className="fas fa-times text-2xl text-[#CD1818]"></i>
        </div>

        <aside className={`grid w-full md:w-3/12 p-2`}>
          <AsideSubscriptionContent
            whoseItem={whoseItem}
            closeSideBarOnNavigate={closeSideBarOnNavigate}
          />
        </aside>
      </aside>
    </>
  );
};
export default AsideSubscription;
