import { useTranslation } from "react-i18next"

export default function AboutContent({ slot }) {
    const { t } = useTranslation();
    return (
        <section className="grid grid-cols-2 items-center mob:grid-cols-1">
            <div>
                <div className="my-5">
                    <h2 className="f-bold text-4xl my-4">{t('with')} <span className="text-[#CD1818]">Dulijan</span> Academy</h2>
                    <p className="text-gray-500">
                        <span className="f-bold">
                            {t('aboutDemo')}
                        </span>
                        <br />
                        <span className="f-rg">
                            {t('aboutDemo')}
                        </span>

                    </p>
                </div>
                {slot}
            </div>
            <div className="flex flex-col items-center py-16" >
                <div><img src="/imgs/logo.png" alt="logo" title="Dulijan Academy" className="w-full" /></div>
                <div><img src="/imgs/logoName.png" alt="logo" title="Dulijan Academy" className="w-full" /></div>
            </div>
        </section>
    )
}