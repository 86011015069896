import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { useRef } from "react";
import "swiper/css";
import ReviewBox from "../../UI/CustomUI/reviewBox";

const TrainerReviews = ({ reviews }) => {
  const swiperRef = useRef(null);

  return (
    <>
      <section className="flex items-center px-3 gap-4 my-2">
        <button
          onClick={() => {
            if (swiperRef.current) {
              const swiper = swiperRef.current.swiper;
              swiper.slidePrev();
            }
          }}
          className="swiper-btn"
        >
          {
            <i
              className={`fa-solid  fa-xl ${
                localStorage.getItem("i18nextLng") === "en"
                  ? "fa-chevron-left"
                  : "fa-chevron-right"
              } arrow`}
            ></i>
          }
        </button>
        <Swiper
          ref={swiperRef}
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          pagination={{ clickable: false }}
          scrollbar={{ draggable: true }}
          className="w-full"
          spaceBetween={10}
          breakpoints={{
            576: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            767: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            1600: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
          }}
        >
          {reviews?.map((review) => (
            <SwiperSlide key={review.id}>
              <ReviewBox review={review} />
            </SwiperSlide>
          ))}
        </Swiper>
        <button
          onClick={() => {
            if (swiperRef.current) {
              const swiper = swiperRef.current.swiper;
              swiper.slideNext();
            }
          }}
          className="swiper-btn"
        >
          {
            <i
              className={`fa-solid  fa-xl ${
                localStorage.getItem("i18nextLng") === "en"
                  ? "fa-chevron-right"
                  : "fa-chevron-left"
              } arrow`}
            ></i>
          }
        </button>
      </section>
    </>
  );
};
export default TrainerReviews;
