import { useEffect, useState } from "react";
import RatingComponent from "../RatingComponent";
import { useDispatch, useSelector } from "react-redux";
import { GetRate, RateSelector } from "../../../store/slices/ChoosedDTSlice";
import { useLocation, useNavigate } from "react-router-dom";

export const RatingRadio = ({ arrTitle, arr }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [checkIfNum, setCheckIfNum] = useState(0);
    const dispatch = useDispatch();
    const GetRateNumber = (e) => {
        if (location.pathname !== '/courses') {
            navigate('/courses');
        }
        dispatch(GetRate(e.target.value));
        setCheckIfNum(e.target.value)
    };
    const toSeeIfNull = useSelector(RateSelector);

    useEffect(() => {
        if (toSeeIfNull === null) {
            setCheckIfNum(null)
        }
    }, [toSeeIfNull]);

    return (
        <div className="p-3 rounded-lg bg-[#F7F7F9] text-black tab:w-full">
            <h1 className="f-bold text-xl">{arrTitle}</h1>
            <div>
                {arr?.map((rate, index) => (
                    <div key={index} className="my-2">
                        <div className="flex items-center gap-2">
                            <input
                                type="radio"
                                value={rate}
                                name={`rate`}
                                id={`rate-${rate}`}
                                onChange={GetRateNumber}
                                checked={checkIfNum === String(rate)}
                            />
                            <RatingComponent rate={rate} />
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}