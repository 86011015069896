import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const COURSE_DAYS_IN_WEEK = process.env.REACT_APP_SUBSCRIBE_COURSE_SHOW_WEEK_URL + `?locale=${localStorage.getItem('i18nextLng')}`
const PACKAGE_DAYS_IN_WEEK = process.env.REACT_APP_SUBSCRIBE_PACKAGE_SHOW_WEEK_URL + `?locale=${localStorage.getItem('i18nextLng')}`

export const GetDaysInWeek = createAsyncThunk(
    'WeekDTSlice/GetDaysInWeek',
    async ({ id, whatItem }, { rejectWithValue }) => {
        try {
            if (whatItem === 'packages') {
                const response = await axios.post(PACKAGE_DAYS_IN_WEEK, {
                    week_id: id
                })
                return response.data.data
            } else {
                const response = await axios.post(COURSE_DAYS_IN_WEEK, {
                    week_id: id
                })
                return response.data.data
            }
        } catch (err) {
            return rejectWithValue(err.response?.data?.data || "SORRY , Course Month Data is not Available ")
        }
    }
)

const WeekDTSlice = createSlice({
    name: 'WeekDTSlice',
    initialState: {
        data: [],
        loading: false,
        err: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetDaysInWeek.pending, (state) => {
                state.loading = true;
                state.err = null
            })
            .addCase(GetDaysInWeek.fulfilled, (state, action) => {
                state.data = action.payload;
                state.loading = false
            })
            .addCase(GetDaysInWeek.rejected, (state, action) => {
                state.loading = false;
                state.err = action.payload
            });
    }
})

export const WeekDT = (state) => state.WeekDT.data
export const WeekDTLoading = (state) => state.WeekDT.loading
export const WeekDTErr = (state) => state.WeekDT.err

export default WeekDTSlice.reducer