import axios from "axios";
import Cookies from 'js-cookie';

const RateCourse = async (theRate, theComment, subscriptionId) => {
    const RATE_URL = process.env.REACT_APP_RATE_COURSE;
    const token = Cookies.get('token') || ''

    try {
        await axios.post(RATE_URL, {
            course_id: subscriptionId,
            rate: theRate,
            comment: theComment
        },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            })
        return { done: true, err: null }
    } catch (err) {
        return { done: false, err: err }
    }
}
export default RateCourse