import React from "react";
import { useTranslation } from "react-i18next";
import PaymentHook from "../../hooks/CustomHooks/PaymentHooks/PaymentHook";
import Loading from "../../UI/Loading/Loading";

const PackagePriceCard = ({ packDT, packIndex }) => {
  const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
  const { t } = useTranslation();
  const { handlePayment, loading } = PaymentHook();
  return (
    <div
      className={`grid p-3 my-3 border relative rounded-lg w-[19.5rem] h-[32.875rem] tab:w-full mob:w-[30rem] ${
        (packIndex + 1) % 2 === 0 ? "bg-[#AC0B2E] text-white" : null
      }`}
    >
      {loading && <Loading />}
      <div>
        <div className="flex items-center justify-between">
          <div>
            <h1
              className={`f-black  text-xl ${
                (packIndex + 1) % 2 === 0 ? "text-[#FF9F9F]" : "text-[#CD1818]"
              }`}
            >
              {packDT?.price / 100} {t("riyal")}
            </h1>
            <h3 className="f-bold text-lg">
              {packDT?.translations
                ? packDT?.translations[0]?.name
                : t("service")}
            </h3>
          </div>
          <div>
            <img
              src={`${IMG_URL}${packDT.image}`}
              alt={packDT.title}
              title={packDT.title}
              className="block w-12 h-12 mob:hidden"
            />
          </div>
        </div>
        <hr className="my-10" />
        <div>
          <ul className="p-0">
            {packDT.details?.map((detail, index) => (
              <li
                key={index}
                className="f-semi-bold my-2 mob:text-sm mob:w-[15rem]"
              >
                {"\u25A0"} {detail}
              </li>
            ))}
            {!packDT?.details && (
              <li
                dangerouslySetInnerHTML={{
                  __html: packDT?.translations
                    ? packDT?.translations[0]?.desc
                    : t("details"),
                }}
                className="f-bold"
              />
            )}
          </ul>
        </div>
      </div>
      <div className="self-end">
        <div>
          <i className="fa-solid fa-star" style={{ color: "#FFD43B" }}></i>
          <span className="f-semi-bold mx-1">
            ( {packDT?.rate} {t("rate")} )
          </span>
        </div>
        <div className="my-3">
          <button
            onClick={() =>
              handlePayment(packDT?.price, packDT?.package_id, "package")
            }
            className={`animate-btn ${
              (packIndex + 1) % 2 === 0 ? "text-white" : null
            }`}
          >
            {t("buyNow")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PackagePriceCard;
