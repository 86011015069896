import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import "swiper/css";
import PackageSwiper from "./packageSwiper";
import { Link } from "react-router-dom";
import CourseBox from "../../UI/CoursesUI/courseBox";
import { useTranslation } from "react-i18next";

const PackagesContent = ({ courses }) => {
  const swiperRef = useRef(null);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (swiperRef.current) {
      const swiper = swiperRef.current.swiper;

      const updateButtonState = () => {
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
      };

      swiper.on("slideChange", updateButtonState);
      updateButtonState();
    }
  }, []);

  return (
    <>
      <main className="mainBg px-12 tab:px-2">
        <main className="p-6 tab:px-2">
          <div className="flex justify-between px-16 w-full my-5 mob:flex-col mob:gap-4 tab:px-3">
            <div className="flex flex-col items-center w-fit tab:items-start">
              <h1 className="f-bold text-2xl mob:mx-5">{t("packages")}</h1>
              <div>
                <img src="/imgs/curve.png" alt="curve" className="w-auto" />
              </div>
            </div>
            <div className="text-gray-500">
              <Link to={`/packages`} className="f-bold text-xl mob:text-lg">
                {t("watchMore")}
              </Link>
            </div>
          </div>
          <section className="my-10">
            <PackageSwiper
              boxClassName="grid gap-4 mx-2"
              seeFavourite={true}
              swiperClassName={`w-full tab:h-full mob:h-full`}
              // imgClass={`h-[15.625rem] w-[21rem] tab:h-[32rem] object-center tab:object-center mob:h-[23rem] tab:w-full`}
              mainBox={`w-[24rem] tab:w-full`}
            />
          </section>
        </main>
      </main>
      <main className="p-6 px-12 my-10 tab:p-2">
        <div className="flex justify-between w-full px-16 my-5 mob:flex-col mob:gap-4 tab:px-3">
          <div className="flex flex-col items-center w-fit tab:items-start">
            <h1 className="f-bold text-2xl mob:mx-5">{t("courses")}</h1>
            <div>
              <img src="/imgs/curve.png" alt="curve" className="w-auto" />
            </div>
          </div>
          <div className="text-gray-500 ">
            <Link
              to={`/courses`}
              className="f-bold text-xl mx-9 mob:text-lg mob:mx-0"
            >
              {t("watchMore")}
            </Link>
          </div>
        </div>
        <section className="flex items-center gap-4 my-10">
          <button
            onClick={() => {
              if (swiperRef.current) {
                const swiper = swiperRef.current.swiper;
                swiper.slidePrev();
              }
            }}
            className={`swiper-btn ${
              isBeginning ? "opacity-40 cursor-not-allowed" : ""
            } `}
            disabled={isBeginning}
          >
            {
              <i
                className={`fa-solid  fa-xl ${
                  localStorage.getItem("i18nextLng") === "en"
                    ? "fa-chevron-left"
                    : "fa-chevron-right"
                } arrow`}
              ></i>
            }
          </button>

          <Swiper
            ref={swiperRef}
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            pagination={{ clickable: false }}
            breakpoints={{
              576: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              767: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1200: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
              1600: {
                slidesPerView: 4,
                spaceBetween: 10,
              },
            }}
            scrollbar={{ draggable: true }}
            className="w-full"
            spaceBetween={0}
          >
            {courses?.map((course, courseIndex) => (
              <SwiperSlide key={courseIndex}>
                <CourseBox
                  course={course}
                  key={courseIndex}
                  className={`h-[13.6875rem] object-cover tab:w-full`}
                />
              </SwiperSlide>
            ))}
          </Swiper>

          <button
            onClick={() => {
              if (swiperRef.current) {
                const swiper = swiperRef.current.swiper;
                swiper.slideNext();
              }
            }}
            className={`swiper-btn ${
              isEnd ? "opacity-40 cursor-not-allowed" : ""
            }`}
            disabled={isEnd}
          >
            {
              <i
                className={`fa-solid  fa-xl ${
                  localStorage.getItem("i18nextLng") === "en"
                    ? "fa-chevron-right"
                    : "fa-chevron-left"
                } arrow`}
              ></i>
            }
          </button>
        </section>
      </main>
    </>
  );
};

export default PackagesContent;
