import { useTranslation } from "react-i18next"
import RateCourse from "../../hooks/CustomHooks/SubscribeHooks/RateCourse";
import { useState } from "react";
import { useParams } from "react-router-dom";
const SubscriptionComments = () => {
    const { t } = useTranslation();
    const params = useParams();
    const [comment, setComment] = useState('');
    const [rating, setRating] = useState(0);

    const [hovered, setHovered] = useState(0);
    const [rateErr, setRateErr] = useState(false);

    const [hasSent, setHasSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [commErr, setCommErr] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (rating === 0) {
            setRateErr(true);
        } else {
            setLoading(true)
            const { done, err } = await RateCourse(rating, comment, params.subscriptionId)
            setHasSent(done);
            setCommErr(err?.response?.data?.message);
            setRateErr(false);
            setLoading(false);
        }
    };
    return (
        <>
            <div className={`my-4 rounded-lg p-4 dataBox`}>
                {/* FIRST COMMENT */}
                <div>
                    <div className="flex items-start gap-2">
                        <div className="w-12 h-12 rounded-full bg-black block"></div>
                        <div>
                            <h2 className="f-bold text-lg m-0">{t('name1')}</h2>
                            <p className="text-gray-400 f-rg">{t('subscriber')}</p>
                        </div>
                    </div>
                    <div className="my-2 flex justify-between items-center">
                        <p className="f-semi-bold w-3/4">
                            اريد استفسار عن كميه الاكل المطلوبه من 200 سعره
                        </p>
                        <p className="f-bold text-sm text-gray-600">
                            {t('from')} 15 {t('day')}
                        </p>
                    </div>
                </div>
                {/* REPLY SEC */}
                <div className="flex items-start gap-2">
                    <div className="flex items-center gap-1 my-1">
                        <span><img src="/icons/red-arrow-reply.svg" alt="reply" className="w-7" /></span>
                        <span className="f-rg text-gray-400 text-sm text-nowrap">{t('reply')}</span>
                    </div>
                    <hr className="h-2 w-full" />
                </div>
                {/* REPLY COMMENT */}
                <div className="px-3">
                    <div className="flex items-start gap-2">
                        <div className="w-10 h-10 rounded-full bg-gray-500 block"></div>
                        <div>
                            <h2 className="f-bold text-sm m-0">{t('name2')}</h2>
                            <p className="text-gray-400 text-xs f-rg">{t('constructor')}</p>
                        </div>
                    </div>
                    <div className="my-2 flex justify-between items-center">
                        <p className="f-semi-bold text-sm w-3/4">
                            شكرا على استفسارك استاذ عبدالله . سيمكنك ان تستخدم نص عربي لتحديد المساحه
                        </p>
                        <p className="f-bold text-sm text-gray-600">
                            {t('from')} 15 {t('day')}
                        </p>
                    </div>
                </div>
            </div>
            {/* WRITE A COMMENT */}
            <div className="my-3">
                <hr />
            </div>
            {
                params.whatItem === 'courses' && <div>
                    <div>
                        <form onSubmit={handleSubmit} className="flex items-center justify-between p-2 w-full border-t-2 border-b-2">
                            <div className="flex items-center justify-center gap-1">
                                {[1, 2, 3, 4, 5].map((value) => (
                                    <label
                                        key={value}
                                        className="cursor-pointer"
                                        onMouseEnter={() => setHovered(value)}
                                        onMouseLeave={() => setHovered(0)}
                                        onClick={() => setRating(value)}
                                    >
                                        <input
                                            type="radio"
                                            name="rate"
                                            value={value}
                                            className="hidden"
                                            required
                                            defaultChecked={value === 4 && true}
                                            onChange={(e) => setRating(e.target.value)}
                                        />
                                        <i
                                            className={`fa-solid fa-star text-2xl transition-all duration-150 ${value <= (hovered || rating) ? 'text-yellow-400' : 'text-gray-300'
                                                }`}
                                        ></i>
                                    </label>
                                ))}
                            </div>

                            <input
                                type="text"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder={t('writeComment')}
                                className="text-black bg-white w-full outline-none text-base rounded-md f-bold px-2 py-2"
                                required
                            />
                            {
                                loading ? (
                                    <div className="bg-white w-11 h-11 flex items-center justify-center rounded-lg">
                                        <div className="animate-spin rounded-full border-t-2 border-b-2 border-black h-7 w-7 z-50"></div>
                                    </div>
                                ) : hasSent ? (
                                    <div className="text-xl p-2 rounded-md bg-white">✅</div>
                                ) : (
                                    <button type="submit" className="bg-white text-[#CD1818] scale-125 p-2 rounded-md">
                                        <i className="fa-solid fa-paper-plane"></i>
                                    </button>
                                )
                            }

                        </form>
                        {rateErr && <p className="f-bold font-bold text-xl text-[#CD1818] my-3">{t('rateErr')}</p>}
                        {commErr && <p className="f-bold font-bold text-xl text-[#CD1818] my-3">{commErr}</p>}
                    </div>
                </div>
            }
        </>
    )

}

export default SubscriptionComments