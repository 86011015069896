import { useParams } from "react-router-dom";
import NavComponent from "../../UI/CustomUI/NavComponent";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../UI/CustomUI/LoadingComponent";
import { useEffect } from "react";
import {
  PackageDT,
  PackageDTErr,
  PackageDTLoading,
  ShowPackageDetails,
} from "../../store/slices/PackageDTSlice";
import { useTranslation } from "react-i18next";
import PackagePriceCard from "../Packages/PackagePriceCard";

const PackageDetails = () => {
  const dispatch = useDispatch();
  const param = useParams();
  const NowPackageDTS = useSelector(PackageDT);
  const NowPackErr = useSelector(PackageDTErr);
  const NowPackageLoading = useSelector(PackageDTLoading);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(ShowPackageDetails(param.packId));
  }, [dispatch, param.packId]);

  // FOR ANY ERROR
  if (NowPackErr) {
    return (
      <div className="text-2xl text-center m-auto py-11">
        <span className="font-bold">404 | ERROR</span>
        <p className="my-5 f-bold">{t("notAvailablePK")}</p>
      </div>
    );
  }
  // IF LOADING
  if (NowPackageLoading)
    return (
      <LoadingComponent
        parentClass={`grid grid-cols-3 gap-3 justify-items-center mob:flex mob:scroll-smooth mob:justify-start mob:snap-x mob:overflow-x-auto hide-scrollbar`}
        childClass={`h-[20rem]`}
        childrenNumber={3}
      />
    );
  return (
    <>
      <NavComponent
        firstURL={`/packages`}
        firstName={t("packages")}
        secName={t("packageTrain")}
      />
      <main className="mb-14">
        <section className="flex flex-col md:flex-row justify-center items-center gap-3 my-10 py-4 px-40 tab:px-6 mob:px-2 mob:gap-1 mob:scroll-smooth mob:justify-start mob:snap-x mob:overflow-x-auto hide-scrollbar">
          {NowPackageDTS?.map((item, i) => (
            <PackagePriceCard key={i} packDT={item} packIndex={i} />
          ))}
        </section>
        {NowPackageDTS?.length <= 0 && (
          <div className="f-bold text-2xl text-center text-[#CD1818]">
            {t("sorryNoDT")}
          </div>
        )}
        <hr className="mx-28 my-5 mob:mx-4" />
        <section className="mx-28 mob:mx-4">
          <div className="flex flex-col items-center w-fit">
            <h1 className="f-bold text-2xl ">{t("details")}</h1>
            <div>
              <img src="/imgs/curve.png" alt="curve" className="w-auto" />
            </div>
          </div>
          <div className="my-4">
            <ul>
              {NowPackageDTS?.details?.map((detail, i) => (
                <li key={i} className="f-bold my-3 text-xl mob:text-base">
                  {"\u25A0"} {detail}
                </li>
              ))}
              {!NowPackageDTS?.details && (
                <div className="f-bold text-2xl text-center py-10 text-[#CD1818]">
                  {t("sorryNoDT")}
                </div>
              )}
            </ul>
          </div>
        </section>
      </main>
    </>
  );
};
export default PackageDetails;
