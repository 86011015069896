import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TrainerReviews from "../HomeComponents/trainerReviews";
import SubscriptionVideoBox from "../../UI/SubscriptionsUI/SubscriptionVideoBox";
import LoadingComponent from "../../UI/CustomUI/LoadingComponent";
import NavComponent from "../../UI/CustomUI/NavComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  SubscriptionItemDT,
  NowItem,
  NowItemRelatedCourses,
  NowItemReviews,
  LoadingItemDetails,
  ErrorItemDetails,
} from "../../store/slices/ItemDTSlice";
import {
  CategoriesErr,
  CategoriesLoading,
  CategoriesSelector,
  GettingCategories,
} from "../../store/slices/ChoosedDTSlice";
import { useTranslation } from "react-i18next";
import { CategoryRadio } from "../../UI/CoursesUI/CourseSideBarBoxs/CategoryRadio";
import { LangRadio } from "../../UI/CoursesUI/CourseSideBarBoxs/LangRadio";
import { PriceRadio } from "../../UI/CoursesUI/CourseSideBarBoxs/PriceRadio";
import { RatingRadio } from "../../UI/CoursesUI/CourseSideBarBoxs/RatingRadio";
import PaymentHook from "../../hooks/CustomHooks/PaymentHooks/PaymentHook";
import Loading from "../../UI/Loading/Loading";

const CoursesTrainingPage = () => {
  const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
  const { handlePayment, loading } = PaymentHook();
  const { t } = useTranslation();
  const str = t("str");

  // FRONTEND JOBS
  const [seeSideBar, setSeeSideBar] = useState(false);

  const seeBar = () => {
    setSeeSideBar((prevBool) => !prevBool);
  };

  // ASIDE BAR DATA
  const langs = [
    { id: 1, locale: "all", name: t("allLngs") },
    { id: 2, locale: "ar", name: t("ar") },
    { id: 3, locale: "en", name: t("en") },
  ];
  const prices = [
    { id: 1, name: t("allCat") },
    { id: 2, name: t("free") },
    { id: 3, name: t("paid") },
  ];
  const rates = [5, 4, 3, 2, 1];

  // GETTING DATA
  const param = useParams();
  const dispatch = useDispatch();
  const reviews = useSelector(NowItemReviews);
  const relatedCourses = useSelector(NowItemRelatedCourses);
  const whoseCourse = useSelector(NowItem);
  const loadingItemDetails = useSelector(LoadingItemDetails);
  const error = useSelector(ErrorItemDetails);

  // CATEGORIES SELECTORS
  const categories = useSelector(CategoriesSelector);
  const catLoading = useSelector(CategoriesLoading);
  const catErr = useSelector(CategoriesErr);

  useEffect(() => {
    dispatch(
      SubscriptionItemDT({
        id: param.courseId,
        whatItem: "courses",
      })
    );
  }, [dispatch, param.courseId]);

  useEffect(() => {
    dispatch(GettingCategories());
  }, []);

  // IF ANY ERROR
  if (!whoseCourse || error)
    return (
      <div className="text-2xl text-center m-auto py-11">
        <span className="font-bold">404 | ERROR</span>
        <p className="my-5 f-bold">{t("soryyNoData")}</p>
      </div>
    );

  // IF LOADING DATA
  if (!relatedCourses || loadingItemDetails)
    return (
      <LoadingComponent
        parentClass={`grid grid-cols-4 p-4 gap-4`}
        childClass={[
          "h-[10rem] rounded-xl col-span-4",
          `h-svh rounded-xl col-span-1`,
          `h-svh rounded-xl col-span-3`,
        ]}
        childrenNumber={3}
      />
    );

  if (!reviews)
    return (
      <LoadingComponent
        parentClass={`grid grid-cols-3 gap-3 justify-items-center mob:flex mob:scroll-smooth mob:justify-start mob:snap-x mob:overflow-x-auto hide-scrollbar`}
        childClass={`h-[20rem] w-[5rem]`}
        childrenNumber={4}
      />
    );

  return (
    <>
      <NavComponent
        firstName={t("courses")}
        firstURL={`/courses`}
        secName={`${t("courseNum")} ${whoseCourse.id}`}
        secURL={`/courses/${whoseCourse.id}`}
      />
      <main className="my-8 px-3">
        {seeSideBar && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 z-10"
            onClick={seeBar} // CLICKING ON IT TO REMOVE MENU
          />
        )}
        <main className=" flex gap-12 mob:gap-5 items-start my-12">
          <div className="absolute border rounded-2xl top-28 hidden tab:block">
            <button className="f-bold text-lg py-1 px-4" onClick={seeBar}>
              {t("menu")}
            </button>
          </div>
          <aside className="grid gap-4 w-3/12 p-2 tab:hidden">
            {catLoading ? (
              <LoadingComponent
                parentClass={`grid gap-4 w-full`}
                childClass={[
                  "h-[8rem] rounded-lg w-full",
                  "h-[8rem] rounded-lg w-full",
                  "h-[8rem] rounded-lg w-full",
                  "h-[8rem] rounded-lg w-full",
                ]}
                childrenNumber={4}
              />
            ) : (
              <>
                <CategoryRadio arr={categories} arrTitle={t("categories")} />
                <LangRadio arr={langs} arrTitle={t("lang")} />
                <PriceRadio arr={prices} arrTitle={t("price")} />
                <RatingRadio arr={rates} arrTitle={t("theRate")} />
              </>
            )}
            {catErr && (
              <div className="f-bold text-xl text-[#CD1818]">
                {t("soryyNoData")}
              </div>
            )}
          </aside>
          <aside
            className={`bg-white h-full absolute hidden tab:block mob:w-[84%] tab:w-2/4 px-4 top-0 z-20 transition-all duration-150 py-7
                ${
                  localStorage.getItem("i18nextLng") === "en"
                    ? seeSideBar
                      ? "-translate-x-5"
                      : "-translate-x-[100rem]"
                    : seeSideBar
                    ? "translate-x-5"
                    : "translate-x-[100rem]"
                } `}
          >
            <p
              className={`f-bold relative w-fit top-0 cursor-pointer tab:block ${
                localStorage.getItem("i18nextLng") === "en"
                  ? "left-[90%]"
                  : "-left-[90%]"
              }`}
              onClick={seeBar}
            >
              <i className="fas fa-times text-2xl text-[#CD1818]"></i>
            </p>
            <aside className="grid gap-4 w-full p-2">
              {catLoading ? (
                <LoadingComponent
                  parentClass={`grid gap-4 py-4 w-full`}
                  childClass={[
                    "h-[8rem] rounded-lg w-full",
                    "h-[8rem] rounded-lg w-full",
                    "h-[8rem] rounded-lg w-full",
                    "h-[8rem] rounded-lg w-full",
                  ]}
                  childrenNumber={4}
                />
              ) : (
                <>
                  <CategoryRadio arr={categories} arrTitle={t("categories")} />
                  <LangRadio arr={langs} arrTitle={t("lang")} />
                  <PriceRadio arr={prices} arrTitle={t("price")} />
                  <RatingRadio arr={rates} arrTitle={t("theRate")} />
                </>
              )}
              {catErr && (
                <div className="f-bold text-xl text-[#CD1818]">
                  {t("soryyNoData")}
                </div>
              )}
            </aside>
          </aside>
          <section className="px-12 w-9/12 py-2 tab:w-full tab:mt-5 tab:px-1">
            <div className="my-7">
              <h1 className="f-bold text-3xl">{t("introVid")}</h1>
              <div className="px-2 my-5">
                <video
                  className="rounded w-full h-[350px] md:h-[450px] lg:h-[600px]"
                  src={`${IMG_URL}${whoseCourse?.video}`}
                  poster={`${IMG_URL}${whoseCourse?.image}`}
                  controls
                >
                  <source
                    src={`${IMG_URL}${whoseCourse?.video}`}
                    type="video/mp4"
                  />
                  <source
                    src={`${IMG_URL}${whoseCourse?.video}`}
                    type="video/webm"
                  />
                  <source
                    src={`${IMG_URL}${whoseCourse?.video}`}
                    type="video/ogg"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
              <h2
                className="f-bold text-2xl"
                dangerouslySetInnerHTML={{
                  __html: whoseCourse?.translations
                    ? whoseCourse?.translations?.[0]?.name
                    : null,
                }}
              />
              <p
                className="f-semi-bold text-lg w-8/12 tab:w-auto"
                dangerouslySetInnerHTML={{
                  __html: whoseCourse?.translations
                    ? whoseCourse?.translations?.[0]?.desc
                    : null,
                }}
              />
              <p className="f-bold w-8/12 text-[#CD1818] text-xl tab:w-auto">
                {whoseCourse?.price} {t("riyal")}
              </p>
            </div>
            <hr />
            <div>
              <div className="flex flex-col items-center w-fit mt-16 mb-4">
                <h1 className="f-bold text-2xl ">{t("details")}</h1>
                <div>
                  <img src="/imgs/curve.png" alt="curve" className="w-auto" />
                </div>
              </div>
              <div className="mb-24 mt-11">
                <ul className="p-0">
                  {whoseCourse?.details?.map((detail, index) => (
                    <li key={index} className="f-semi-bold my-2 mob:text-base">
                      {"\u25A0"} {detail}
                    </li>
                  ))}
                  {!whoseCourse?.details && (
                    <li
                      className="f-semi-bold"
                      dangerouslySetInnerHTML={{
                        __html: whoseCourse?.translations
                          ? whoseCourse?.translations?.[0]?.desc
                          : null,
                      }}
                    />
                  )}
                </ul>
              </div>
            </div>
            <hr />
            <div className="my-12">
              <div className="flex flex-col items-center w-fit my-4">
                <h1 className="f-bold text-2xl ">{t("price")}</h1>
                <div>
                  <img src="/imgs/curve.png" alt="curve" className="w-auto" />
                </div>
              </div>
              <div className="border-2 p-3 relative">
                {loading && <Loading />}
                <h1 className="text-center text-3xl f-bold text-[#CD1818]">
                  {whoseCourse?.price / 100} {t("riyal")}
                </h1>
                <hr className="my-2" />
                <p className="text-center text-lg f-bold">{str}</p>
                <div className="flex justify-center my-2 w-full">
                  <button
                    className="animate-btn font-bold"
                    onClick={() =>
                      handlePayment(
                        whoseCourse?.price,
                        whoseCourse?.id,
                        "course"
                      )
                    }
                  >
                    {t("subscribeNow")}
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <main className="my-12">
              <div className="flex flex-col items-center w-fit">
                <h1 className="f-bold text-2xl ">{t("rates")}</h1>
                <div>
                  <img src="/imgs/curve.png" alt="curve" className="w-auto" />
                </div>
              </div>
              <section className="py-14 my-4 mob:py-0">
                {reviews.length > 0 ? (
                  <TrainerReviews reviews={reviews} />
                ) : (
                  <div className="f-bold text-2xl text-[#CD1818] text-center">
                    {t("sorryNoDT")}
                  </div>
                )}
              </section>
            </main>
            <hr />
            <section className="my-12">
              <div className="flex flex-col items-center w-fit">
                <h1 className="f-bold text-2xl ">{t("related")}</h1>
                <div>
                  <img src="/imgs/curve.png" alt="curve" className="w-auto" />
                </div>
              </div>
              <section className="p-6 my-2">
                {relatedCourses?.map((course) => (
                  <Link key={course.id} to={`/courses/${course.id}`}>
                    <SubscriptionVideoBox
                      key={course?.id}
                      item={course}
                      index={course?.id}
                      totalLength={relatedCourses?.length}
                    />
                  </Link>
                ))}
              </section>
            </section>
          </section>
        </main>
      </main>
    </>
  );
};
export default CoursesTrainingPage;
