import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AsideSubscription from "./AsideComponents/AsideSubscription";
import { useDispatch, useSelector } from "react-redux";
import LoadingComponent from "../../UI/CustomUI/LoadingComponent";
import { WeekDT, WeekDTErr, WeekDTLoading, GetDaysInWeek } from "../../store/slices/SubscriptionSlices/WeekDTSlice";
import { NowItem } from "../../store/slices/ItemDTSlice";
import { useTranslation } from "react-i18next";
import Cookies from 'js-cookie';

const SubscriptionsMonthWeek = () => {
    const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
    const params = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [seeSideBar, setSeeSideBar] = useState(false);
    const [notFound, setNotFound] = useState(false);

    useEffect(() => {
        if (Cookies.get('token')) {
            dispatch(GetDaysInWeek({
                id: params.week.slice(5),
                whatItem: params.whatItem
            }))
            setNotFound(false)
        } else {
            setNotFound(true)
        }
    }, [dispatch, params.whatItem, params.week]);


    const seeBar = () => {
        setSeeSideBar(prevBool => !prevBool)
    };

    const closeSideBarOnNavigate = () => {
        setSeeSideBar(false);
    };

    const DaysInWeek = useSelector(WeekDT);
    const loading = useSelector(WeekDTLoading);
    const error = useSelector(WeekDTErr);
    const whoseItem = useSelector(NowItem);

    // ERROR
    if (error) return <div className="text-2xl text-center m-auto py-11">
        <span className="font-bold">404 | ERROR</span>
        <p className="my-5 f-bold">
            {t('sorryNoDT')}
        </p>
    </div>

    // LOADING
    if (loading) return <LoadingComponent parentClass={`grid grid-cols-1 gap-4 p-2`} childClass={`h-[28rem]`} childrenNumber={5} />

    if (notFound) return (
        <div className="f-bold text-[#CD1818] text-center text-2xl py-24">
            <p>{t('sorryNoUser')}</p>
            <div className="f-bold text-blue-500 underline">
                <Link to={`/login`}>
                    {t('canLoginHere')}
                </Link>
            </div>
        </div>
    );

    return (
        <main className="flex gap-12 p-4 mob:gap-5 items-start my-12 min-h-screen">
            <AsideSubscription seeBar={seeBar} seeSideBar={seeSideBar} whoseItem={whoseItem} closeSideBarOnNavigate={closeSideBarOnNavigate} loading={loading} error={error} />
            <section className={`p-6 tab:p-0 transition-filter duration-300 ${seeSideBar ? 'filter blur-sm' : ''}`}>
                <section className="p-2">
                    <h1 className="text-xl text-nowrap">
                        <span className="f-bold text-[#CD1818]"> {t("week")} {params?.week?.replace('week-', '')}  </span> -
                        <span className="f-bold text-[#CD1818]"> {t('month')} {params?.month?.replace('month-', '')}</span>
                    </h1>
                    <section className="grid grid-cols-3 gap-4 mob:grid-cols-2 my-8">
                        {
                            DaysInWeek?.map((item) => (
                                <Link key={item.id} to={`/subscriptions/${params.whatItem}/${item?.id}/month-${params?.month?.slice(6)}/week-${params?.week.slice(5)}/day-${item?.id}`}>
                                    <div key={item.id}>
                                        {
                                            item?.video ? <div> <video src={`${IMG_URL}${item.video}`} controls alt={`video-${item.id}`}>
                                                Your browser does not support the video tag.
                                            </video></div> : <div className={`w-[15rem] h-[10rem] ${localStorage.getItem('theme') === 'dark' ? 'darkLoading' : 'lightLoading'}`}></div>
                                        }
                                        <div className="flex items-baseline justify-between my-3 px-2">
                                            <h2 className="f-bold text-xl">{t('day')} {item?.id}</h2>
                                            <p className="f-semi-bold text-sm vidNum">
                                                {t('video')} ({DaysInWeek?.length})
                                            </p>
                                        </div>
                                    </div>
                                </Link>
                            ))
                        }
                    </section>
                    {
                        DaysInWeek.length <= 0 && <div className="text-center f-bold">
                            {t('sorryNoDT')}-{params.week.slice(5)}
                        </div>
                    }
                </section>
            </section >
        </main>
    )
}
export default SubscriptionsMonthWeek