import { useTranslation } from "react-i18next";

export default function ReviewBox({ review }) {
  const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
  const { t } = useTranslation();
  return (
    <div
      className={`p-4 grid rounded-lg border-2 max-w-full w-[26.25rem] h-[22.5rem] tab:w-full ${
        localStorage.getItem("theme") === "dark"
          ? "border-[#ffffffde]"
          : "border-black"
      }`}
    >
      <div>
        {[...Array(review?.rate)]?.map((_, index) => (
          <i
            className="fa-solid fa-star"
            style={{ color: "#FFD43B" }}
            key={index}
          ></i>
        ))}
      </div>
      <div>
        <p className="f-rg text-gray-500 text-base">{review?.comment}</p>
      </div>
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-2">
          <div>
            <img
              src={`${IMG_URL}${review?.user?.image}`}
              alt={`${review?.user?.first_name}-profile-img`}
              className="w-14 rounded-full h-14"
            />
          </div>

          <div>
            <h2 className="f-bold text-lg m-0">
              <span className="mx-1">{review?.user?.first_name}</span>
              <span>{review?.user?.last_name}</span>
            </h2>
            <h4 className="f-rg text-gray-500 text-sm">{t("subscriber")}</h4>
          </div>
        </div>
        <div>
          <img
            src={`${
              localStorage.getItem("theme") === "dark"
                ? "/icons/inverted-commas-wh.svg"
                : "/icons/inverted-commas.svg"
            }`}
            alt="review"
            className="w-12"
          />
        </div>
      </div>
    </div>
  );
}
