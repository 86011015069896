import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import i18nextBrowserLanguagedetector from "i18next-browser-languagedetector";

import en from './languages/en.json'
import ar from './languages/ar.json'

i18next
    .use(i18nextBrowserLanguagedetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: en },
            ar: { translation: ar }
        },
        fallbackLng: 'ar',
        lng: localStorage.getItem('i18nextLng') || 'ar',
        interpolation: {
            escapeValue: false
        },
        detection: {
            order: ['localStorage', 'navigator'],
            caches: ['localStorage']
        }
    })

export default i18next