import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
const NavComponent = ({ firstURL, firstName, secURL, secName }) => {
    const { t } = useTranslation();
    const [theme, setTheme] = useState('');
    const th = localStorage.getItem('theme') || 'light';

    useEffect(() => {
        setTheme(th)
    }, [th]);

    return (
        <main className={`mainBg mob:hidden ${secName && secURL ? 'py-12' : 'py-6'}`}>
            {
                !secName && !secURL && (
                    <h1 className={`px-8 py-3 my-3 ${localStorage.getItem('i18nextLng') === 'en' ? 'font-bold text-5xl' : 'f-bold text-4xl'}`}>{firstName}</h1>
                )
            }
            <div className="px-8 py-1 flex items-center gap-2">
                <Link to={`/`} className={`${localStorage.getItem('i18nextLng') === 'en' ? 'font-bold' : 'f-bold'} text-xl`}>{t('home')} </Link>
                <span>
                    {
                        <i className={`fa-solid fa-chevron-left ${localStorage.getItem('i18nextLng') === 'en' ? 'rotate-180 mt-1' : ''} ${theme === 'dark' ? 'text-white' : 'text-black'}`}></i>
                    }
                </span>
                <div className={`${secName || secURL ? '' : 'text-[#CD1818]'} flex items-center gap-2`}>
                    <Link to={firstURL} className={`${localStorage.getItem('i18nextLng') === 'en' ? 'font-bold' : 'f-bold'} text-xl`}>
                        {firstName}
                    </Link>
                    {secName && <span>
                        {
                            <i className={`fa-solid fa-chevron-left ${localStorage.getItem('i18nextLng') === 'en' ? 'rotate-180 mt-1' : ''} ${theme === 'dark' ? 'text-white' : 'text-black'}`}></i>
                        }
                    </span>
                    }
                </div>
                {
                    secName && (
                        <div className={`text-[#CD1818] ${localStorage.getItem('i18nextLng') === 'en' ? 'font-bold' : 'f-bold'} text-xl`}>
                            <Link to={secURL} className="f-bold">
                                {secName}
                            </Link>
                        </div>
                    )
                }
            </div>
        </main>
    )
}
export default NavComponent