import axios from "axios";
import Cookies from 'js-cookie';

const AddToFavourite = async (id, whereAddFavourite) => {
    const COURSE_FAV = process.env.REACT_APP_ADD_COURSE_TO_FAVOURITE;
    const PACKAGE_FAV = process.env.REACT_APP_ADD_PACKAGE_TO_FAVOURITE;
    const token = Cookies.get('token');

    try {
        if (whereAddFavourite === 'packages') {

            await axios.post(PACKAGE_FAV, { package_id: id }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            return { done: true, err: false };
        } else {

            await axios.post(COURSE_FAV, { course_id: id }, {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            });

            return { done: true, err: false };
        }
    } catch (err) {
        return { done: false, err: true };
    }
}
export default AddToFavourite