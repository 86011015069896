import { useEffect, useRef, useState } from "react"
import { Link, useParams } from "react-router-dom"
import SubscriptionVideoBox from "../../UI/SubscriptionsUI/SubscriptionVideoBox";
import SubscriptionComments from "../../UI/SubscriptionsUI/SubscriptionComments";
import AsideSubscription from "./AsideComponents/AsideSubscription";
import NavComponent from "../../UI/CustomUI/NavComponent";
import { useDispatch, useSelector } from "react-redux";
import { ErrorItemDetails, LoadingItemDetails, NowItem, NowItemRelatedCourses, SubscriptionItemDT } from "../../store/slices/ItemDTSlice";
import LoadingComponent from "../../UI/CustomUI/LoadingComponent";
import { useTranslation } from "react-i18next";
import { ReturnedDTErr } from "../../store/slices/SubscriptionSlices/CourseOrPackageDT";
import Cookies from 'js-cookie';

const SubscriptionsMWDay = () => {
    const IMG_URL = process.env.REACT_APP_ALL_IMGS_API_URL;
    const params = useParams();
    const dispatch = useDispatch();
    const [notFound, setNotFound] = useState(false);
    const [seeSideBar, setSeeSideBar] = useState(false);
    const [showQues, setShowQues] = useState(false);
    const [hasQuesSeen, setHasQuesSeen] = useState(false);
    const [quesValue, setQuesValue] = useState('');
    const videoTimeRef = useRef(null);
    const { t } = useTranslation();


    const seeVideoTime = () => {
        const videoElement = videoTimeRef.current;
        if (!videoElement) return;

        const currentVideoTime = videoElement.currentTime;
        const totalVideoDuration = videoElement.duration;

        if (Math.floor(currentVideoTime) === Math.floor(totalVideoDuration / 2)) {
            if (!hasQuesSeen) { // لو السؤال متعرضش قبل كدا , الاجابه اه ) (true)
                videoElement.pause(); // وقف الفيديو
                setHasQuesSeen(true)
                setShowQues(true); // السؤال يظهر 
                videoElement.controls = false;
            }
        }
    };

    const ShowAnimation = (e) => {
        if (quesValue) {
            e.target.classList.remove('no-ques-answer')
            e.target.classList.add('animate-btn')
        }
    }

    const HideMsg_ReturnControls = () => {
        if (quesValue) {
            if (hasQuesSeen) { // لو اتعرض , اخفي السؤال و رجع ال contorl
                setShowQues(false); // اخفيه بقا 
                videoTimeRef.current?.play();
                videoTimeRef.current.controls = true;
            }
        }
    };


    useEffect(() => {
        if (Cookies.get('token')) {
            dispatch(SubscriptionItemDT({
                id: params.subscriptionId,
                whatItem: params.whatItem
            }))
            setNotFound(false)
        } else {
            setNotFound(true)
        }
    }, [dispatch, params.subscriptionId, params.whatItem])

    const seeBar = () => {
        setSeeSideBar(prevBool => !prevBool)
    };

    const closeSideBarOnNavigate = () => {
        setSeeSideBar(false);
    };

    const relatedCourses = useSelector(NowItemRelatedCourses);
    const loading = useSelector(LoadingItemDetails);
    const itemsError = useSelector(ErrorItemDetails);
    const whoseItem = useSelector(NowItem);
    const mainErr = useSelector(ReturnedDTErr);

    if (loading && !whoseItem) return (<LoadingComponent parentClass={`grid grid-cols-[25%_75%] gap-4 w-full`} childClass={`h-[25rem] w-full rounded-lg`} childrenNumber={2} />)

    if (mainErr) return (
        <div className="text-2xl text-center m-auto py-11">
            <span className="font-bold">404 | ERROR</span>
            <p className="my-5 f-bold"> {t('sorryNoUser')}
            </p>
        </div>
    )

    if (notFound) return (<div className="f-bold text-[#CD1818] text-center text-2xl py-24">
        <p>{t('sorryNoUser')}</p>
        <div className="f-bold text-blue-500 underline">
            <Link to={`/login`}>
                {t('canLoginHere')}
            </Link>
        </div>
    </div>)

    return (
        <>
            <NavComponent firstName={t('subscriptions')} firstURL={`/subscriptions`} secName={t('packDemo')} />
            <main className="flex gap-12 p-4 mob:gap-5 items-start my-12">
                <AsideSubscription seeBar={seeBar} seeSideBar={seeSideBar} whoseItem={whoseItem} closeSideBarOnNavigate={closeSideBarOnNavigate} loading={loading} error={itemsError} />
                <section className={`p-6 tab:p-0 transition-filter duration-300 ${seeSideBar ? 'filter blur-sm' : ''}`}>
                    <div>
                        <h3 className="f-bold text-2xl my-3 mob:text-xl"
                        >
                            {t('packDemo')}
                            <span className="text-[#CD1818]"> ( {t('day')} {params.day.replace('day-', '')} )</span>
                        </h3>
                        <div className="relative flex justify-center items-center mob:h-[13rem]">
                            {
                                whoseItem?.video ? (<video
                                    ref={videoTimeRef}
                                    onTimeUpdate={seeVideoTime}
                                    controls
                                    poster={`${IMG_URL}${whoseItem?.image}`}
                                    src={`${IMG_URL}${whoseItem?.video}`}
                                    className="rounded w-full mob:h-full"
                                >
                                    <source src={`${IMG_URL}${whoseItem?.video}`} type="video/mp4" />
                                    <source src={`${IMG_URL}${whoseItem?.video}`} type="video/webm" />
                                    <source src={`${IMG_URL}${whoseItem?.video}`} type="video/ogg" />
                                    {/*  لو المتصفح مبيدعمش */}
                                    Your browser does not support the video tag.
                                </video>) : (
                                    <img src={`${IMG_URL}${whoseItem?.image}`} alt={whoseItem?.translations ? whoseItem?.translations[0].name : t('courseName')} className="w-auto mob:h-full" />
                                )
                            }
                            {/* QUESTION DIV */}
                            {
                                whoseItem?.video && <div className={`bg-white absolute p-[1rem] w-[37rem] tab:w-[23rem] mob:w-[13rem] rounded-lg h-fit tab:p-1 mob:px-2 mob:mt-2 ${showQues ? 'block' : 'hidden'}`}>
                                    <div className="flex flex-col items-center justify-end w-fit mx-0 tab:mx-0 mob:my-0">
                                        <h1 className="f-bold text-2xl tab:text-lg mob:text-sm">{t('ques')}</h1>
                                        <div><img src="/imgs/curve.png" alt="curve" className="w-32 tab:w-20" /></div>
                                    </div>
                                    <div className="my-[1rem] tab:my-2">
                                        <fieldset onChange={(e) => setQuesValue(e.target.id)}>
                                            <h3 className="bg-[#F7F7F9] f-bold text-base w-fit p-1 m-0 mob:text-sm">{t("urGender")}</h3>
                                            <hr className="mob:hidden" />
                                            <div className="tab:flex tab:gap-5 mob:my-1">
                                                <div className="flex items-center">
                                                    <input type="radio" name="gender" id="male" className="w-3 h-3 appearance-none border-2 border-gray-400 rounded-none checked:bg-blue-600" />
                                                    <label htmlFor="male" className="f-rg mx-2 mob:text-sm">
                                                        ( - ) : {t('male')}
                                                    </label>
                                                </div>
                                                <div className="flex items-center">
                                                    <input type="radio" name="gender" id="female" className="w-3 h-3 appearance-none border-2 border-gray-400 rounded-none checked:bg-blue-600 tab:mt-0" />
                                                    <label htmlFor="female" className="f-rg mx-2 mob:text-sm">
                                                        ( - ) : {t('female')}
                                                    </label>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <div className="flex justify-center pt-6 h-fit tab:pt-2 mob:pt-3">
                                            <button className="no-ques-answer w-fit mob:text-xs" onClick={HideMsg_ReturnControls} onMouseEnter={ShowAnimation}>
                                                <i className="fas fa-play"></i>
                                                {t('continue')}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div >
                        <div className="flex justify-between items-center my-3 tab:flex-col tab:items-start">
                            <p className="f-bold w-8/12 tab:w-auto" dangerouslySetInnerHTML={{ __html: whoseItem.translations ? whoseItem.translations[0].desc : t('courseDesc') }} />
                            <div style={{ textDecoration: 'underline', textDecorationColor: 'red' }} className="f-bold text-lg">
                                ( {whoseItem.id} ) : <span className="text-gray-400 f-bold text-lg">( {relatedCourses.length} )</span>
                            </div>
                        </div>
                    </div >
                    <hr />
                    <section className="p-0">
                        {
                            // لو كورسات تودينا لصفحه الكورس , لو باقات تودينا لصفحه الباقه الي هي بال id
                            relatedCourses?.map(item => (
                                params.whatItem === 'courses' ? (
                                    <Link key={item?.id} to={`/courses/${item?.id}`}>
                                        <SubscriptionVideoBox item={item} index={item?.id} totalLength={relatedCourses?.length} />
                                    </Link>) : params.whatItem === 'packages' ? (
                                        <Link key={item?.id} to={`/subscriptions/packages/${item?.id}`}>
                                            <SubscriptionVideoBox item={item} index={item?.id} totalLength={relatedCourses?.length} />
                                        </Link>) : itemsError
                            ))
                        }
                    </section>
                    <section className="my-12 p-0">
                        <div className="flex justify-between items-start">
                            <div className="flex flex-col items-center w-fit mx-6 tab:mx-0">
                                <h1 className="f-bold text-2xl ">{t('subscComments')}</h1>
                                <div><img src="/imgs/curve.png" alt="curve" className="w-auto" /></div>
                            </div>
                            <div>
                                <span className="f-black">(15) {t('comment')}</span>
                            </div>
                        </div>
                        <section>
                            <SubscriptionComments />
                        </section>
                    </section>
                </section>
            </main>
        </>
    )
}
export default SubscriptionsMWDay