import React from 'react'
const Loading = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full w-full absolute bg-[#ffffffdb] z-10">
        <div className="w-14 h-14 border-8 border-t-8 border-gray-200 border-t-[#CD1818] rounded-full animate-spin"></div>
    </div>
);
}

export default Loading
