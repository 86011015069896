import { useDispatch, useSelector } from "react-redux";
import { CategorySelector, GetCategory } from "../../../store/slices/ChoosedDTSlice";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const CategoryRadio = ({ arr, arrTitle }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [checkIfId, setCheckIfId] = useState(0);
    const dispatch = useDispatch();
    const toSeeIfNull = useSelector(CategorySelector);
    const GetCategoryId = (e) => {
        if (location.pathname !== '/courses') {
            navigate('/courses');
        }
        dispatch(GetCategory(e.target.value));
        setCheckIfId(e.target.value);
    };
    useEffect(() => {
        if (toSeeIfNull === null) {
            setCheckIfId(null)
        }
    }, [toSeeIfNull]);

    return (
        <div className="p-3 rounded-lg bg-[#F7F7F9] text-black tab:w-full">
            <h1 className="f-bold text-xl">{arrTitle}</h1>
            <div>
                {
                    arr && arr?.map((arrItem) => (
                        <div key={arrItem?.id} className="w-full">
                            <input
                                type="radio"
                                value={arrItem?.id}
                                name={arrTitle}
                                id={`${arrTitle}-${arrItem?.id}`}
                                onChange={GetCategoryId}
                                checked={checkIfId === String(arrItem.id)}
                                aria-label={arrItem.translations?.[0]?.name || "Category"}
                            />
                            <label
                                htmlFor={`${arrTitle}-${arrItem?.id}`}
                                className="f-rg mx-2 text-nowrap"
                            >
                                {
                                    arrItem.translations && arrItem.translations?.[0]?.name
                                }
                            </label>
                        </div>

                    ))
                }
            </div>
        </div>
    )
}