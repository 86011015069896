import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Mail, newPassErr, newPassLoading, RechangePass, VeriCode } from "../../store/slices/ForgetPassSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie'
const NewPass = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [pass, setPass] = useState('');
    const [confirmPass, setConfirmPass] = useState('')
    const [saveDT, setSaveDT] = useState(false);
    const [loggedInMSG, setLoggedInMSG] = useState(false);
    const [err, setErr] = useState(null);

    const veriMail = useSelector(Mail);
    const veriCode = useSelector(VeriCode);
    const loading = useSelector(newPassLoading);
    const error = useSelector(newPassErr)

    const Rechange = async (e) => {
        e.preventDefault();
        try {
            const result = await dispatch(RechangePass({
                lastMail: veriMail,
                newPass: pass,
                confPass: confirmPass,
                veriCode: veriCode
            }))
            if (RechangePass.rejected.match(result)) {
                setErr(result.payload);
            } else {
                navigate('/profile')
                setErr(null);
            }
        } catch (err) {
            setErr(err)
        }
    }

    useEffect(() => {
        const AlertWhenRefresh = (event) => {
            if (!saveDT || pass !== '' || confirmPass !== '') {
                const confirmationMessage = "Are you sure you want to leave this page? Unsaved changes will be lost.";
                event.returnValue = confirmationMessage;
                return confirmationMessage;
            }
        };

        window.addEventListener('beforeunload', AlertWhenRefresh);

        return () => {
            window.removeEventListener('beforeunload', AlertWhenRefresh);
        };
    }, [saveDT, pass, confirmPass]);

    useEffect(() => {
        if (Cookies.get('token')) {
            setLoggedInMSG(true)
            const loggedInTimer = setTimeout(() => {
                navigate('/profile')
            }, 4000)
            return () => clearTimeout(loggedInTimer)
        }
    }, [navigate])
    return (
        <>
            {
                loggedInMSG ?
                    <div className="f-bold h-dvh w-full text-3xl tab:text-2xl flex flex-col gap-4 justify-center items-center bg-white z-40">
                        <div className="text-center">
                            {t('uCanEdit')}
                        </div>
                        <div className="text-center">
                            {t('navigateYou')}
                        </div>
                    </div>
                    :
                    <>
                        <main className="py-5 px-6 mob:px-2">
                            <div className="flex flex-col items-center w-fit mx-6 tab:mx-0">
                                <h1 className="f-ex-bold text-2xl ">{t('forgetPassIntro')}</h1>
                                <div>
                                    <img src="/imgs/curve.png" alt="curve" className="w-auto" />
                                </div>
                            </div>
                            <section className="flex justify-center items-center my-5">
                                <section className={`my-22 mx-auto p-4 shadow-md rounded w-[43rem] tab:w-full dataBox`}>
                                    {/* FORM */}
                                    <form method="POST" onSubmit={Rechange} className="pr-2">
                                        <div className="px-2">
                                            <label htmlFor="pass" className="f-bold text-xl">
                                                {t('newPass')}
                                            </label>
                                            <br />
                                            <input
                                                type="password"
                                                id="pass"
                                                name="pass"
                                                value={pass}
                                                onChange={(e) => setPass(e.target.value)}
                                                placeholder={t('writePass')}
                                                className="my-2 border rounded-sm outline-none f-rg px-2 py-1 shadow-sm w-full"
                                                required
                                                aria-required={true}
                                            />
                                        </div>
                                        <div className="px-2">
                                            <label htmlFor="new-pass" className="f-bold text-xl">
                                                {t('confirmPass')}
                                            </label>
                                            <br />
                                            <input
                                                type="password"
                                                id="new-pass"
                                                name="new-pass"
                                                value={confirmPass}
                                                onChange={(e) => setConfirmPass(e.target.value)}
                                                placeholder={t('writeConfirm')}
                                                className="my-2 border rounded-sm outline-none f-rg px-2 py-1 shadow-sm w-full"
                                                required
                                                aria-required={true}
                                            />
                                        </div>
                                        <div>
                                            <button className="submit-btn f-bold my-4" type="submit" onClick={() => setSaveDT(true)}>
                                                {
                                                    loading ? (
                                                        <div className="flex justify-center">
                                                            <span className="mx-2 f-bold"
                                                            >{t("plsWait")}</span>
                                                            <div className="animate-spin rounded-full border-t-2 border-b-2 border-white h-7 w-7 mr-2"></div>
                                                        </div>
                                                    ) : (
                                                        <div>
                                                            <span className="mx-2 f-bold">{t('confirmCode')}</span>{" "}

                                                        </div>
                                                    )}
                                            </button>
                                        </div>
                                    </form>
                                </section>
                            </section>
                            {
                                (err || error) && <div className="text-xl text-center text-[#CD1818] font-bold f-bold my-4">
                                    <p>404 | ERROR</p>
                                    <p>{err}</p>
                                    <p>{error}</p>
                                </div>
                            }

                        </main>
                    </>
            }

        </>
    )
}
export default NewPass